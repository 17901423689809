<template>
    <section class="page login-page layout-login">
        <loading-indicator v-if="!regions" />
        <template v-else>
            <reset-password-component v-if="!reset_completed" :regions="regions" v-on:reset-completed="reset_completed = true" />
            <div v-else class="container mt-5">
                <div class="row">
                    <div class="col">
                        <div class="alert alert-info">
                            <div class="p form-group">
                                <div class="col">{{ $t('reset_password.succeeded') }}</div>
                            </div>
                            <div class="p form-group">
                                <div class="col">
                                    <router-link to="/user/login">{{ $t('reset_password.label_login') }}</router-link>
                                    |
                                    <router-link to="/">{{ $t('reset_password.label_go_home') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script type="text/javascript">
import ResetPasswordComponent from './Components/ResetPassword.vue';

export default {
    components: { ResetPasswordComponent },

    data() {
        return { regions: null, reset_completed: false };
    },

    created() {
        const self = this;
        $.callGetApi(this, '/api/v1/config/regions').then((resp) => {
            if (resp) {
                self.regions = resp.data;
            }
        });
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>