<template>
    <button type="submit" v-bind:class="buttonCss" v-bind:disabled="disabled">{{ buttonText }}</button>
</template>

<script>
export default {
    props: ['text'],

    data() {
        return {
            // Display text of the submit button
            buttonText: null,

            submitting: false,
            completed: false
        }
    },

    watch: {
        text: function (to, from) {
            this.buttonText = this.text || this.$t('general.submit');
        }
    },

    created() {
        this.buttonText = this.text || this.$t('general.submit');
    },

    computed: {
        buttonCss: function () {
            if (this.completed)
                return 'btn btn-submit btn-completed';

            if (this.submitting)
                return 'btn btn-submit btn-loading';

            return 'btn btn-submit';
        },

        disabled: function () {
            return this.submitting || this.completed;
        }
    },

    methods: {
        /**
         * Mark the submit button as submitting.
         */
        submit: function () {
            this.submitting = true;
        },

        /**
         * Mark the submit button as completed.
         */
        complete: function () {
            this.completed = true;
        },

        /**
         * Reset the status of the submit button
         */
        reset: function () {
            this.submitting = false;
            this.completed = false;
        },

        /**
         * Set submitting as false.
         */
        resetSubmit: function () {
            this.submitting = false;
        }
    }
}
</script>