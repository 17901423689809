<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row" v-if="list && list.length < 1">
                <div class="col">
                    <h1>{{ $t('bank_account.title') }}</h1>

                    <!-- A link to add a new account -->
                    <div class="form-group">
                        <div class="row align-items-center">
                            <div class="col">
                                <a class="link-to-add" @click="link_to()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="1.25rem" height="1.25rem">
                                        <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z" />
                                    </svg>
                                    {{ $t('bank_account.label_add') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <loading-indicator v-if="!list" />
            <template v-else>
                <!-- No existing accounts? -->
                <div v-if="list.length === 0" class="form-group">
                    <div class="row">
                        <div class="col">
                            <div class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
                        </div>
                    </div>
                </div>

                <!-- List all accounts -->
                <div v-else class="form-group">
                    <div class="row">
                        <div class="col">
                            <ol class="list-group list-group-numbered">
                                <li v-for="acc in list" :key="acc.uid" class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        <div class="fw-bold">{{ acc.bank }} {{ acc.branch }}</div>
                                        {{ acc.bankCardNo }} {{ acc.bankCardHolder }}
                                    </div>
                                    <router-link :to="{ path: `/user/bankaccount/${acc.uid}` }">{{ $t('general.edit') }}</router-link>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>
<style scoped>
.link-to-add {
    cursor: pointer;
}
</style>
<script>
export default {
    data() {
        return {
            list: null
        }
    },

    mounted() {
        this.initAsync();
    },

    methods: {
        initAsync: async function () {
            // List all accounts with payment source as bank.
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0) {
                this.list = json.data;
            }
        },
        link_to() {
            const self = this;
            if(self.list.length >= 3) {
                $.top_error(self.$t('bank_account.label_bank_account_err'));
                return
            } else {
                self.$router.push('/user/bankaccount')
            }
        }
    }
}
</script>