<template>
    <div v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block">
            <div class="market-wrap">
                <dl class="dl-style">
                    <dd class="dd-style" v-for="(symbol, index) in filteredSymbols" :key="index"
                        @click="gotoTrade(symbol)">
                        <router-link :to="symbol.getRoutePath()" v-if="index < 8">
                            <div class="text-uppercase d-flex align-items-center">
                                <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                <div>{{ symbol.metadata.name }}</div>
                            </div>
                            <div growing-ignore="false">${{ symbol.price }}</div>
                            <div growing-ignore="false">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.price_change_pct }}
                                </span>
                            </div>
                        </router-link>
                    </dd>
                </dl>
            </div>
        </section>
        <section class="homepage-market-sm d-lg-none d-md-none">
            <dl class="dl-style-sm-f">
                <dd class="dl-style-sm" v-for="(symbol, index) in filteredSymbols" :key="index"
                    @click="gotoTrade(symbol)">
                    <div class="dd-style-sm" v-if="index < 4">
                        <div class="dd-style-sm-c">
                            <dl>
                                <dt class="text-uppercase d-flex align-items-center token-name">
                                    <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                    <div>{{ symbol.metadata.name }}</div>
                                </dt>
                            </dl>
                            <dl :class="symbol.up ? 'bg-up-mb' : 'bg-down-mb'" growing-ignore="true">{{
                                symbol.price_change_pct }}</dl>
                        </div>
                    </div>
                    <div class="dd-style-sm" v-if="index < 8 && index >= 4">
                        <div class="dd-style-sm-c">
                            <dl>
                                <dt class="text-uppercase d-flex align-items-center token-name">
                                    <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                    <div>{{ symbol.metadata.name }}</div>
                                </dt>
                            </dl>
                            <dl :class="symbol.up ? 'bg-up-mb' : 'bg-down-mb'" growing-ignore="true">{{
                                symbol.price_change_pct }}</dl>
                        </div>
                    </div>
                </dd>
            </dl>
        </section>
    </div>
</template>
<style scoped>
.homepage-market .market-wrap dl dd a {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 0;
}

.dl-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dd-style {
    min-width: 47%;
}

@media(max-width:1024px) {
    .page .bg-up-mb {
        background-color: #fff;
        color: var(--up);
    }

    .page .bg-down-mb {
        background-color: #fff;
        color: var(--down);
    }

    .dl-style-sm-f{
        display: flex;
        flex-wrap: wrap;
    }
    .dl-style-sm {
        display: flex;
        width: 50%;
        justify-content: space-between;
    }

    .dd-style-sm {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .dd-style-sm-c{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .homepage-market-sm {
        display: block !important;
    }

    .homepage-market {
        display: none !important;
    }
}

@media screen and (min-width:1024px) and (max-width:1400px) {
    .dd-style {
        min-width: none;
        width: 100%;
    }
}
</style>

<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm'],

    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }

            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                const arr = this.symbols.filter((s) => {
                    return s.up && s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
                return arr.sort((a, b) => {
                    return parseFloat(b.price_change_pct.replace('%', '')) - parseFloat(a.price_change_pct.replace('%', ''));
                })
            } else {
                // Return symbols for the specified type.

                const arr = this.symbols.filter((s) => {
                    return s.up && s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                });

                return arr.sort((a, b) => {
                    return parseFloat(b.price_change_pct.replace('%', '')) - parseFloat(a.price_change_pct.replace('%', ''));
                })
            }
        }
    },

    methods: {
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },

        getVisibleSids: function () {
            const output = [];
            $(this.filteredSymbols).each((index, sym) => {
                output.push(sym.metadata.id);
            });

            return output;
        }
    }
};
</script>