<template>
    <div style="height: 0; overflow: hidden">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <symbol v-for="sym in customized_symbols" :key="sym.metadata.id" :id="'symicon-' + sym.metadata.id" viewBox="0 0 100 100" v-html="sym.metadata.svgIcon"></symbol>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['symbols'],

    computed: {
        customized_symbols: function () {
            return (this.symbols || []).filter((s) => s.metadata && s.metadata.svgIcon);
        }
    }
};
</script>