<template>
    <section class="page settings-page">
        <template v-if="profile">
            <section class="user-top-header container d-none d-md-block">
                <div class="user-box">
                    <img src="../../assets/images/v4.1/userAvatar.png" alt="" class="avatar">
                    <div class="vip-box">
                        <div class="username">{{ formatString(this.profile.realName) }}</div>
                        <div v-if="profile.vipLevel > 0" class="d-flex align-items-center vip-label">
                            <img :src='"@/assets/images/vip/" + profile.vipLevel + ".png"' alt="" class="vip-img" />
                        </div>
                    </div>
                </div>
                <div class="user-info">
                    <div class="user-info-item">
                        <span class="info-des">{{ $t('user.label_account') }}</span>
                        <div class="info-value">{{ userPhone }}</div>
                    </div>
                    <div class="user-info-item">
                        <span class="info-des">{{ $t('user.label_id_verification') }}</span>
                        <div class="info-value">{{ userStatus }}</div>
                    </div>
                </div>
            </section>
            <section class="user-top-header-md container d-block d-md-none">
                <div class="user-box-md">
                    <div class="user-box-left">
                        <img src="../../assets/images/v4.1/userAvatar.png" alt="" class="avatar-md">
                        <div class="user-login-info">
                            <div class="user-name">Hi,{{ formatString(this.profile.realName) }}</div>
                            <div v-if="profile.vipLevel > 0" class="d-flex align-items-center vip-label-sm">
                                <img :src='"@/assets/images/vip/" + profile.vipLevel + ".png"' alt="" class="vip-img-sm" />
                            </div>
                        </div>
                    </div>
                    <div class="user-box-btn" @click="showInfo = !showInfo">{{ $t('orders.query_more_orders') }}</div>
                </div>
                <div class="user-info-box-md">
                    <div class="user-info-item">
                        <div class="item-des">{{ $t('user.label_account') }}</div>
                        <div class="item-value">{{ showInfo ? userPhone : '***' }}</div>
                    </div>
                    <div class="user-info-item">
                        <div class="item-des">{{ $t('user.label_id_verification') }}</div>
                        <div class="item-value">{{ showInfo ? userStatus : '***' }}</div>
                    </div>
                </div>
            </section>

            <section class="w-100">
                <settings-panel-component v-bind:profile="profile" />
            </section>
        </template>
        <div v-else>
            <loading-indicator />
        </div>
    </section>
</template>

<style scoped>
.page {
    background-color: #F6F6F7;
}

.user-top-header {
    margin: 40px auto;
    background-image: url(@/assets/images/v4.1/userTop.jpg);
    padding: 39px 0 34px;
}

.user-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    margin-bottom: 8px;
    width: 66px;
}

.username {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #111111;
}

.vip-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
}

.vip-img {
    width: 60px;
    margin-right: 5px;
}
.vip-img-sm {
    width: 50px;
    margin-right: 5px;
}
.vip-label {
    justify-content: center;
    position: absolute;
    left: -70px;
}

.user-login-info {
    display: flex;
    align-items: center;
    min-width: 150px;
}

.vip-label-sm {
    justify-content: center;
    margin-left: 5px;
}

.user-info {
    display: flex;
    justify-content: center;
}

.user-info-item {
    display: flex;
    margin-left: 80px;
}

.user-info-item:first-child {
    margin-left: 0;
}

.info-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #7F8490;
    line-height: 22px;
    margin-right: 8px;
}

.info-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #18191C;
    line-height: 22px;
}

.user-top-header-md {
    padding: 30px 16px 0;
    background-color: #fff;
}

.user-box-md {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
}

.user-box-left {
    display: flex;
    align-items: center;
}

.avatar-md {
    width: 30px;
    margin-right: 5px;
}

.user-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 21px;
    color: #111111;
    line-height: 17px;
    margin-bottom: 5px;
}

.login-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 18px;
}

.user-box-btn {
    border-radius: 20px;
    border: 1px solid #18191C;
    padding: 8px 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000922;
    line-height: 10px;
    cursor: pointer;
}

.user-info-box-md {
    display: flex;
    flex-wrap: wrap;
}

.user-info-box-md .user-info-item {
    padding: 0 20px 0 0;
    display: block;
    margin: 0 0 20px;
    flex: 1 0 100px;
    min-width: 100px;
    max-width: 100%;
}

.item-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #7F8490;
    line-height: 22px;
}

.item-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #18191C;
    line-height: 22px;
}

@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }

    .d-block {
        display: block !important;
    }
}
</style>

<script>
import '@/assets/svg/icon-warning-sign.svg';
import '@/assets/svg/icon-checked.svg';
import '@/assets/svg/icon-arrow-right.svg';

import NoticeListComponent from './Components/Notices.vue';
import SettingsPanelComponent from './Components/SettingsPanel-4.1.vue';
import { setProfile } from 'utilities/helper';

import { EventBus } from '../../assets/js/event-bus.js';

export default {
    components: { NoticeListComponent, SettingsPanelComponent },
    data() {
        return {
            profile: null,
            id_status: NaN,
            regions: null,
            dafultRegions: null,
            notificationList: null,
            showInfo: true,

            //display xxx***xx str
            originalString: ''
        };
    },
    computed: {
        userCountry() {
            const region = this.profile.phone.split('*')[0].trim();
            if (this.dafultRegions != null && this.regions == null) {
                return this.dafultRegions.filter(val => { return val.areaCode == region[0].name })
            }
            else if (this.regions != null) {
                return this.regions.filter(val => { return val.areaCode == region[0].name })
            } else {
                return '--'
            }
        },
        userPhone() {
            return this.profile.phone
        },
        userStatus() {
            if (this.profile.verifiedLevel >= this.profile.requiredIDLevel) {
                return this.$t('user.label_id_verified')
            } else if (this.id_status === 1) {
                return this.$t('user.label_id_waiting')
            } else {
                return this.$t('user.label_id_incomplete')
            }
        },
        loginTime() {
            if (this.notificationList == null) {
                return '--'
            } else {
                const timestamp = this.notificationList.filter(val => { return val.type == 2 })[0].timeCreated;
                const date = new Date(timestamp);

                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
            }
        }
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
        this.getRegions();
        this.getDafultRegions();
        this.getNotificationList()
    },

    methods: {
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                }

                self.profile = profile;
            }
        },
        getRegions: async function () {
            EventBus.$on('sendRegions', message => {
                this.regions = message;
            })
        },
        getDafultRegions: async function () {
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            this.dafultRegions = resp.data;
        },
        getNotificationList: async function (id = 0x7fffffff) {
            const resp = await $.callPostApi(self, `/api/v1/notification/list?max=${id}`)
            this.notificationList = resp.data
        },

        //display xxx***xx str
        formatString(str) {
            // get str length
            const length = str.length;
            // Define the length of prefixes and suffixes to retain
            const prefixLength = 3;
            const suffixLength = 2;
            // Determine whether the string is long enough
            if (length <= prefixLength + suffixLength) {
                return str;
            }
            // Get prefix and suffix
            const prefix = str.slice(0, prefixLength);
            const suffix = str.slice(length-suffixLength);

            // Construct the * part
            //const stars = '*'.repeat(str.length - prefixLength - suffixLength);

            // Final result of splicing
            return `${prefix}***${suffix}`;
        }
    }
};
</script>