<template>
    <section class="page home-page">
        <section class="pro_layout">
            <loading-indicator v-if="!symbols" />
            <div v-else>
                <div class="home-top">
                    <top-banners v-if="context.homeConfig" :home_config="context.homeConfig" />
                </div>

                <!-- top notice -->
                <div class="page-part"
                    v-if="context.homeConfig && context.homeConfig.promptedNotices && context.homeConfig.promptedNotices.length">
                    <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
                </div>

                <!-- major nav icons for sm screens -->
                <div class="d-block d-md-none sm-navs">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <router-link to="/user/login">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-login" />
                                    </svg>
                                    {{ $t('general.login') }}/{{ $t('general.register') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <!-- Display a tab for the savings feature when it was enabled. -->
                                <router-link v-if="sysconfig.savingsEnabled" to="/saving">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t('home.header_saving') }}
                                </router-link>
                                <router-link v-else to="/user/balance">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t(sysconfig.is_hkmy ? 'home.header_buy_crypto_my' : 'home.header_buy_crypto') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/startup">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-assets" />
                                    </svg>
                                    {{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/notices">
                                    <div class="svg-box"> <svg class="svg-icon notice-svg" viewBox="0 0 256 256">
                                            <use class="use-svg" xlink:href="/dist/svg/icons.svg#v4.2-notice" />
                                        </svg>
                                    </div>
                                    {{ $t('notices.notice_title') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- page title -->
                <div class="page-top" :class="{ 'page-top-hk': sysconfig.is_hk }">
                    <div class="container">
                        <div class="row home-quotes">
                            <div v-for="(sym, pos) in prompted_symbols" :key="pos" class="col">
                                <prompted-symbol_v4 v-if="$version === '4.4' || $version === '4.3'" :symbol="sym" />
                                <prompted-symbol v-else :symbol="sym" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- page coinCrease -->
                <div class="page-coin-crease">
                    <div class="container">
                        <div>
                            <h1 class="crease-title text-title">{{ $t('home.label_up_symbols') }}</h1>
                        </div>
                    </div>
                    <div class="container">
                        <div class="coin-crease">
                            <div class="crease-left">
                                <img src="@/assets/images/v4.4/coinCrease.png" alt="" class="crease-img">
                            </div>
                            <div class="crease-right">
                                <div>
                                    <home-quotes-crease ref="quotesCrease" :symbols="symbols" />
                                </div>
                                <div class="top-title">
                                    {{ $t('home.market_trend') }}
                                </div>
                                <div class="right-desp">
                                    <home-quotes-main-coin ref="quotesMainCoin" :symbols="symbols" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- page quick-buy-coin -->
                <div class="page-mid">
                    <div class="container">
                        <div class="mid-title text-title">
                            <h1>{{ $t('home.guide_trade_long') }}</h1>
                        </div>
                        <div class="mid-contain">
                            <div class="mid-left">
                                <div class="mid-tleft">
                                    <div class="tleft-title text-desp">
                                        <h3>{{ $t('home.risk_notice_line1') }}</h3>
                                    </div>
                                    <div class="tleft-img">
                                        <h3>{{ $t('home.risk_notice_line4',{'platform':sysconfig.name}) }}</h3>
                                    </div>
                                </div>
                                <div class="mid-m-empty">
                                </div>
                                <div class="mid-dleft">
                                    <div class="dleft-title text-desp">
                                        <h2>{{ $t('home.risk_notice_line2', { company: sysconfig.name }) }}</h2>
                                    </div>
                                    <div class="dleft-desp text-desp">
                                        <h3>{{ $t('home.risk_notice_line3') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="mid-right">
                                <img src="@/assets/images/v4.4/intro-compu-2X.png" alt="" class="crease-img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-de">
                    <div class=" container">
                        <div class="de-title text-title">
                            <h1>{{ $t('home.h1',{'platform':sysconfig.name}) }}</h1>
                        </div>
                        <inline-svg-icons :symbols="symbols" />
                        <home-quotes ref="quotes" :symbols="symbols" />
                        <introduction />
                    </div>
                </div>

                <!-- advantages introduction -->
                <!-- <features /> -->

                <!-- posts -->
                <!-- <home-posts v-if="homeConfig" :posts="homeConfig.posts" /> -->
                <partner-links v-if="context && context.homeConfig && context.homeConfig.spl === true" />
            </div>
        </section>
    </section>
</template>

<style scoped>
.coin-crease {
    display: flex;
}

.page-de {
    background-color: #f7f7f5;
}

.trade-now.text-desp {
    text-align: center;
    font-size: 18px;
    padding: 10px 20px;
    border: 1px solid #1A5EEC;
    color: #1A5EEC;
    border: 1px solid #1A5EEC;
    border-radius: 25px;
    display: inline-block;
}

.tleft-img {
    margin: 12px;
}

.mid-m-empty {
    border: 2px solid #BDBABE;
    margin-left: 10px;
    width: 90%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.mid-title,
.de-title,
.crease-title {
    color: #000;
    margin-bottom: 70px;
    margin-top: 20px;
}

.page-mid {
    background-color: #f7f7f5;
}

.mid-contain {
    display: flex;
    background-color: #fff;
    padding: 40px;
}

.mid-left {
    width: 60%;
}

.mid-right {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-title {
    color: #000;
    padding: 10px;
    font-size: 36px;
}

.text-desp h3 {
    color: #000;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 25px;
}

.dleft-title.text-desp h2 {
    font-size: 20px;
    padding: 10px;
    font-weight: bold;
}

.right-desp {
    background-color: #E4E4E4;
}

.top-title {
    color: #000;
    margin-left: 20px;
    margin-bottom: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
}

.crease-left {
    background-color: #fff;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crease-right {
    background-color: #fff;
    width: 55%;
    padding: 40px;
}

.page-coin-crease {
    background-color: #f7f7f5;
    padding: 40px;
}

.sm-navs {
    /* background: #000;
    color: #fff; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.sm-navs a {
    color: var(--link-color);
}

.sm-navs svg.svg-icon {
    display: block;
    height: 1.5rem;
    width: auto;
    margin: auto;
    margin-bottom: 0.5rem;
    fill: var(--primary-base);
    /* stroke: var(--primary-base); */
}

.svg-box {
    height: 1.5rem;
    position: relative;
    margin-bottom: 0.5rem;
}

.sm-navs svg.svg-icon.notice-svg {
    height: 2.8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.row.home-quotes {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
    border: 1px solid #E4E4E4;
}

@media (max-width:1024px) {

    .mid-title,
    .de-title,
    .crease-title,
    .pro_layout {
        color: #000;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .pro_layout{
        margin-top: 0;
    }
    .home-page {
        background-color: #fff;
        color: #000;
        width: 100%;
    }

    .page-top .home-quotes .col {
        width: 50%;
    }

    .row.home-quotes {
        display: flex;
    }

    .coin-crease {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .crease-left {
        width: 100%;
    }

    .crease-right {
        width: 100%;
        padding: 0;
    }

    .page-coin-crease {
        padding: 0;
        background-color: #fff;
    }

    .page-top {
        background-color: #fff;
    }

    .page-mid {
        background-color: #fff;
    }

    .mid-contain {
        flex-direction: column;
        align-items: center;
        padding: 0;
        width: 100%;
    }

    .page-de {
        background-color: #fff;
    }
    .container{
        --bs-gutter-x:1.7rem
    }
    .mid-left,
    .mid-right {
        width: 100%;
    }

    .mid-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        text-align: center;
    }

    .tleft-img {
        padding-right: 24px;
        width: 100%;
        text-align: center;
    }

    .dleft-desp {
        display: none;
    }

    .trade-now.text-title {
        text-align: center;
        font-size: 20px;
        padding: 10px 20px;
        border: 1px solid #1A5EEC;
        color: #1A5EEC;
        width: 100%;
        border-radius: 40px;
    }
    .trade-now.text-desp{
        display: inline-block;
    }
    .text-title,
    .text-title h1 {
        color: #000;
        padding: 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        font-size: 36px;
        font-style: normal;
    }

    .text-desp h3 {
        color: #000;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 25px;
    }

    .dleft-title.text-desp h2 {
        font-size: 20px;
        padding: 10px;
        font-weight: bold;
    }
}

@media(max-width:768px) {
    .crease-img {
        width: 80%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width:1024px) and (max-width:1200px) {
    .crease-img {
        width: 370px;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .d-md-none {
        display: flex !important;
    }
}
</style>

<script type="text/javascript">
// import PostsComponent from './Components/Posts.vue';
import SymbolList from './Components/SymbolList.vue';
import TopBanners from './Components/TopBanners-4.2.vue';
import HomeNotices from './Components/HomeNotices.vue';
import HomePosts from './Components/HomePosts.vue';
// import Features from './Components/Features.vue';
import PromptedSymbols from './Components/PromptedSymbols.vue';
import Introduction from './Components/Introduction-4.4.vue';
import HomeQuotes from './Components/HomeQuotes.vue';
import HomeQuotesCrease from './Components/HomeQuotesCrease.vue';
import HomeQuotesMainCoin from './Components/HomeQuotesMainCoin.vue';
import HomeCarousel from './Components/HomeCarousel.vue';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import PartnerLinks from './Components/_PartnerLinks-4.2.vue';

import PromptedSymbol from './Components/_PromptedSymbol.vue';
import PromptedSymbol_v4 from './Components/_PromptedSymbol-4.1.vue';

// import '@/assets/svg/icon-rocket.svg';
// import '@/assets/svg/icon-team.svg';
import '@/assets/svg/icon-login.svg';
import '@/assets/svg/icon-wallet.svg'
import '@/assets/svg/icon-assets.svg';;
// import '@/assets/svg/icon-trust.svg';
// import '@/assets/svg/icon-dollar-coin.svg';
import '@/assets/svg/icon-invite.svg';
import '@/assets/svg/icon-notice.svg';


import '@/assets/svg/v4.2/v4.2-login.svg';
import '@/assets/svg/v4.2/v4.2-wallet.svg';
import '@/assets/svg/v4.2/v4.2-assets.svg';
import '@/assets/svg/v4.2/v4.2-notice.svg';

import { SymbolInfo } from 'utilities/SymbolInfo.js';
import { getSymbolManagerAsync, getAppContext } from 'utilities/helper';

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { TopBanners, SymbolList, HomeNotices, HomePosts, Introduction, PromptedSymbols, HomeQuotes, HomeQuotesCrease, HomeQuotesMainCoin, HomeCarousel, InlineSvgIcons, PartnerLinks, PromptedSymbol, PromptedSymbol_v4 },

    data() {
        return {
            // All symbols.
            symbols: null,

            // App context
            context: null

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // sort_market: 2,
            // sorted_symbols: null,
            // sort_asc: false
        };
    },

    computed: {
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        },
        prompted_symbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });

            // Only take up to 4 symbols
            if (arr.length > 4) {
                arr = arr.slice(0, 4);
            }
            return arr;
        }
    },
    watch: {
        '$i18n.locale': function (newVal, oldVal) {
            if (newVal) {
                this.context = getAppContext();
            }
        }
    },
    created() {
        g_destoryed = false;
        this.context = getAppContext();

        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // this.sortSymbolsAsync(false);
        },

        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        syncQuotes: function () {
            const self = this;

            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.promptedSymbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                const temp1 = this.$refs.quotesCrease.getVisibleSids();
                const temp2 = this.$refs.quotesMainCoin.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
                if (Array.isArray(temp1)) {
                    for (let i = 0; i < temp1.length; i++) {
                        sids.push(temp1[i]);
                    }
                }
                if (Array.isArray(temp2)) {
                    for (let i = 0; i < temp2.length; i++) {
                        sids.push(temp2[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            if (this.sorted_symbols) {
                $(this.sorted_symbols).each((index, sym) => {
                    sids.push(sym.metadata.id);
                });
            }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                    .then((json) => {
                        const quotes = json.data;
                if (quotes.length > 0) {
                    for (let i = 0; i < quotes.length; i++) {
                        const quote = quotes[i];
                        const sym = g_symbolMap[quote.id];
                        if (sym) {
                            sym.update(quote);
                        }
                    }
                }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            // always restart timer
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }

        // UPDATE:
        // Sorted symbols is deprected for now.
        //
        // changeMarket: function (market) {
        //     this.sort_market = market;
        //     this.sortSymbolsAsync();
        // }

        // UPDATE:
        // Sorted symbols is deprected for now.
        //
        // sortSymbolsAsync: async function (asc) {
        //     this.sort_asc = asc;
        //     this.sorted_symbols = null;

        //     const resp = await $.callGetApi(this, '/api/v1/quotation/topsymbols?type=' + encodeURIComponent(this.sort_market) + '&asc=' + (asc ? 'true' : 'false'));
        //     const output = [];
        //     if (Array.isArray(resp)) {
        //         $(resp).each((index, quote) => {
        //             var sym = g_symbolMap[quote.id];
        //             if (sym) {
        //                 sym.update(quote);
        //                 output.push(sym);
        //             }
        //         });
        //     }
        //     this.sorted_symbols = output;
        // }
    }
};
</script>
