<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('general.deposit') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <deposit-form />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DepositForm from './Components/DepositForm.vue';

export default {
    components: { DepositForm },

    data() {
        return {
            depositConfig: null,

            // All supported fiat deposit providers
            fiatProviders: [],

            // The blockchain deposit provider (at most one)
            blockchainProvider: null,

            submitting: false,

            depositSourceTitle: null,
            depositUrl: null,

            // Indicates wether to display fiat deposit or blockchain deposit?
            fiatDeposit: false
        };
    },

    created() {
        const self = this;
        self.getDepositConfigAsync().catch((err) => {
            self.depositConfig = {
                // Unexpected error
                errcode: -1,
                errmsg: 'Failed to connect to server.'
            };
        });
    },

    methods: {
        getDepositConfigAsync: async function () {
            // Reset default values
            this.depositConfig = null;
            this.fiatProviders = [];
            this.blockchainProvider = null;
            this.fiatDeposit = true;

            // Query config from server side.
            const config = await $.callPostApi(this, '/api/v1/finance/depositconfig');

            // Assign providers for each different type
            if (config && config.data) {
                this.fiatProviders = config.data.filter((p) => p.fiat === true);

                // At most one
                const arr = config.data.filter((p) => p.fiat === false);
                if (arr.length > 0) {
                    const provider = arr[0];
                    if (provider && provider.addresses && provider.addresses.length > 0) {
                        $(provider.addresses).each((index, addr) => {
                            addr.qr_img = null;
                        });

                        // Use fiat deposit by default.
                        this.fiatDeposit = false;
                        this.blockchainProvider = provider;
                    }
                }
            }
            this.depositConfig = config;
        },

        showIFrame: function (title, url) {
            this.depositSourceTitle = title;
            this.depositUrl = url;
        },

        closeIFrame: function () {
            this.depositUrl = null;
        }
    }
};
</script>