<template>
    <section class="page quotes-page">
        <section class="pro_layout">
            <loading-indicator v-if="!symbols" />
            <div v-else>
                <inline-svg-icons :symbols="symbols" />
                <!-- page title -->
                <div class="page-top" :class="{ 'page-top-hk': sysconfig.is_hk }">
                    <div class="container">
                        <h1 class="quotes-title">{{ $t('home.features_title') }}</h1>
                        <div class="quotes-top">
                            <img class="quotes-bg" src="@/assets/images/v4.4/quotes-top-bg.jpg" />
                            <div>
                                <h2 class="title">{{ $t('v42.news_title1') }}</h2>
                                <h2 class="title">{{ $t('v42.news_desp1') }}</h2>
                            </div>
                            <div class="row align-items-center">
                                <!-- <div class="col">
                                <h1>{{ $t('home.footer_quotes') }}</h1>
                            </div> -->
                                <div class="col  d-flex justify-content-center">
                                    <!-- search -->
                                    <div class="input-group input-group-lg">
                                        <div class="input-group-text" :class="{ 'highLight': isActive }">
                                            <svg viewBox="0 0 1024 1024" width="32" height="32">
                                                <path
                                                    d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z"
                                                    fill="#666666" p-id="2868"></path>
                                            </svg>
                                        </div>
                                        <input type="text" @click="highLight" id="myInput" class="form-control" v-model="search_term"
                                            placeholder="BTC, ETH..." autofocus/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="row-box">
                                    <h2 class="h2-t">{{ $t('home.24h_change') }}</h2>
                                    <div class="p">
                                        {{ avg_change.toFixed(2) + '%' }}
                                    </div>
                                </div>
                                <div class="row-box">
                                    <h2 class="h2-t">{{ $t('home.24h_high') }}</h2>
                                    <div class="p">
                                        {{ max_change }}
                                    </div>
                                </div>
                                <div class="row-box">
                                    <h2 class="h2-t">{{ $t('home.24h_low') }}</h2>
                                    <div class="p">
                                        {{ min_change }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="quotes-de">
                        <!-- symbol types -->
                        <div class="row">
                            <div class="col page-top-nav">
                                <ul>
                                    <li :class="{ active: selected_type === 2 }">
                                        <a href="javascript:;" @click="selected_type = 2">{{ $t('general.futures')
                                            }}</a>
                                    </li>
                                    <li :class="{ active: selected_type === 3 }">
                                        <a href="javascript:;" @click="selected_type = 3">{{ $t('general.exchange')
                                            }}</a>
                                    </li>
                                    <li v-if="sysconfig.bOptionEnabled === true"
                                        :class="{ active: selected_type === 1 }">
                                        <a href="javascript:;" @click="selected_type = 1">{{ $t('general.boption')
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- symbols -->
                        <div class="row">
                            <div class="col">
                                <symbol-list-quotes v-if="selected_type === 2" :title="$t('general.futures')"
                                    :symbols="symbols" :typeFilter="2" :searchTerm="search_term"
                                    @symbol-selected="gotoTradePage" />
                                <symbol-list-quotes v-if="selected_type === 3" :title="$t('general.exchange')"
                                    :symbols="symbols" :typeFilter="3" :searchTerm="search_term"
                                    @symbol-selected="gotoTradePage" />
                                <symbol-list-quotes v-if="selected_type === 1" :title="$t('general.boption')"
                                    :symbols="symbols" :typeFilter="1" :searchTerm="search_term"
                                    @symbol-selected="gotoTradePage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<style scoped>
.row {
    justify-content: center;
}

.row-box {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.h2-t {
    color: #000;
    font-size: 20px;
}

.p {
    color: #f03939;
    font-size: 40px;
}

.quotes-title {
    color: #000;
    padding: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    font-size: 36px;
    font-style: normal;
}

.title {
    color: #000;
    font-family: PingFangSC, PingFang SC;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 20px;
}

.quotes-page .page-top {
    background-color: transparent;
}

.pro_layout {
    background-color: #f7f7f5;
}

.quotes-top,
.quotes-de,
.quotes-last {
    background-color: #fff;
    margin-bottom: 70px;
    border: 1px solid #D6D6D6;
}

.quotes-top {
    width: 100%;
    height: 500px;
    padding: 5%;
    position: relative;
}

.quotes-top>div {
    z-index: 2;
    position: relative;
    text-align: center;
}

.quotes-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
}

.quotes-page .page-top-nav {
    margin-top: 0;
    border: 0;
}

.input-group>.form-control {
    /* border-left: 0; */
    color: #006cff;
    position: relative;
}

::placeholder {
    color: #8eb9f6;
}

.input-group>.input-group-text {
    /* color: hsla(0, 0%, 100%, 0.4); */
    border-right: 0;
    background: transparent; 
    padding-left: 0.25rem;
    padding-right: 0;
    position: absolute;
    z-index: 9;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
}

.input-group>.input-group-text.highLight {
    border-color: var(--primary-5) !important;
}

.input-group-text>svg {
    height: 1.5rem;
}

.form-control:focus {
    border-color: var(--primary-5);
    box-shadow: none;
}
.input-group>#myInput {
    border-radius: 1rem;
    text-indent: 2em;
}
@media (min-width: 1024px) {
    .input-group {
        margin: 2rem 0;
        width: 70%;
    }

}

@media (max-width: 1024px) {
    .page-top {
        background-size: 110% 15rem;
    }

    /* .page-top h1 {
        margin-bottom: 1rem;
    } */

    .input-group {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .h2-t {
        color: #000;
        font-size: 10px;
    }

    .p {
        color: #f03939;
        font-size: 20px;
    }

    .quotes-title {
        color: #000;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 36px;
    }

    .title {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .row-box {
        width: 30%;
    }

    .pro_layout {
        background-color: #fff;
    }

    .quotes-top {
        margin-bottom: 35px;
    }
}

.page-top-hk {
    background-image: url(@/assets/images/v4.1/quotes-top-hk.jpg);
}
</style>

<script>
import SymbolList from './Components/SymbolList.vue';
import { SymbolInfo } from 'utilities/SymbolInfo.js';
import { getSymbolManagerAsync } from 'utilities/helper';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import SymbolListQuotes from './Components/SymbolListQuotes.vue';
let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { SymbolList, InlineSvgIcons, SymbolListQuotes },
    data() {
        return {
            // All symbols.
            symbols: null,

            search_term: null,

            // Display future symbols by default.
            selected_type: 2,
            isActive: false
        };
    },

    computed: {
        prompted_symbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            // Only take up to 4 symbols
            if (arr.length > 4) {
                arr = arr.slice(0, 4);
            }
            return arr;
        },
        avg_change: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            let total = 0;
            for (let i = 0; i < arr.length; i++) {
                total += parseFloat(arr[i].price_change_pct.replace('%', ''));
            }
            return total / arr.length;
        },
        min_change: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            arr.sort(function (a, b) {
                return parseFloat(a.price_change_pct.replace('%', '')) - parseFloat(b.price_change_pct.replace('%', ''));
            })
            return arr[0].price_change_pct;
        },
        max_change: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            arr.sort((a, b) => {
                return parseFloat(b.price_change_pct.replace('%', '')) - parseFloat(a.price_change_pct.replace('%', ''));
            })
            return arr[0].price_change_pct;
        },
    },

    created() {
        g_destoryed = false;

        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // BUGFIX:
        // Must unbind the resize event.
        // $(window).unbind('resize');

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        highLight() {
            // const inputElement = document.getElementById('myInput');
            // inputElement.addEventListener('focus', () => {
            //     this.isActive = true;
            // });
            // inputElement.addEventListener('blur', () => {
            //     this.isActive = false;
            // });
            
        },
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                // start to subscribe realtime quote data
                self.syncQuotes();
            }
        },

        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        syncQuotes: function () {
            const self = this;

            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const do_sync = function () {
                const sids = [];
                for (let sid in g_symbolMap) {
                    sids.push(sid);
                }

                if (sids.length > 0) {
                    // Read quotes from server
                    self.$http
                        .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                        .then((json) => {
                            const quotes = json.data;
                            if (quotes.length > 0) {
                                for (let i = 0; i < quotes.length; i++) {
                                    const quote = quotes[i];
                                    const sym = g_symbolMap[quote.id];
                                    if (sym) {
                                        sym.update(quote);
                                    }
                                }
                            }
                        })
                        .then(() => {
                            if (g_destoryed === false) {
                                // always restart timer
                                let delay = 4000 + Math.round(Math.random() * 3000);
                                g_quoteTimerId = setTimeout(do_sync, delay);
                            }
                        });
                }
            };

            do_sync();
        }
    }
};
</script>