<template>
    <div class="slides">
        <div v-if="slides4.length" id="home_carousel" class="carousel slide d-none d-md-block" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div v-for="(slide, slideIndex) in slides4" :key="slideIndex" class="carousel-item" :class="{ active: slideIndex == 0 }">
                    <div class="container">
                        <div class="row">
                            <div v-for="(symbol, index) in slide" :key="index" class="col-3" @click="onSymbolClicked(symbol)">
                                <prompted-symbol_4_1 v-if="$version === '4.1' || $version === '4.3'" :symbol="symbol" />
                                <prompted-symbol v-else :symbol="symbol" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="slides2.length" id="home_carousel_2" class="carousel slide d-block d-md-none" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div v-for="(slide, slideIndex) in slides2" :key="slideIndex" class="carousel-item" :class="{ active: slideIndex == 0 }">
                    <div class="container">
                        <div class="row">
                            <div v-for="(symbol, index) in slide" :key="index" class="col-6 position-relative">
                                <prompted-symbol_4_1 v-if="$version === '4.1' || $version === '4.3'" :symbol="symbol" />
                                <prompted-symbol v-else :symbol="symbol" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';
import PromptedSymbol from './_PromptedSymbol.vue';
import PromptedSymbol_4_1 from './_PromptedSymbol-4.1.vue';
import { Carousel } from 'bootstrap';

export default {
    components: { TokenIcon, PromptedSymbol, PromptedSymbol_4_1 },
    props: ['symbols'],
    data() {
        return {
            slides4: [],
            slides2: [],

            slides4_cs: null,
            slides2_cs: null
        };
    },

    beforeDestroy() {
        let cs = this.slides4_cs;
        if (cs) {
            cs.dispose();
        }

        cs = this.slides2_cs;
        if (cs) {
            cs.dispose();
        }
    },

    mounted() {
        // Create groups: 4 symbols in a group
        var group = [];
        var filteredSymbols = this.symbols.filter((s) => {
            return !s.metadata.testOnly && !s.metadata.name.includes('TEST');
        });
        for (var i = 0; i < filteredSymbols.length; i += 4) {
            group.push(filteredSymbols.slice(i, i + 4));
        }
        this.slides4 = group;

        // Create groups: 2 symbols in a group
        group = [];
        for (var i = 0; i < filteredSymbols.length; i += 2) {
            group.push(filteredSymbols.slice(i, i + 2));
        }
        this.slides2 = group;

        const self = this;
        Vue.nextTick(() => {
            if (self.slides4.length) self.slides4_cs = new Carousel(document.querySelector('#home_carousel'), { interval: 5000, ride: 'carousel' });
            if (self.slides2.length) self.slides2_cs = new Carousel(document.querySelector('#home_carousel_2'), { interval: 5000, ride: 'carousel' });
        });
    },

    methods: {
        onSymbolClicked: function (sym) {
            this.$emit('symbol-clicked', sym);
        },

        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>