import axios from 'axios'

const request = axios.create({
    // baseURL: g_server_root,
    withCredentials: true
})


export function getProfile() {
    return request({
        url: '/api/v1/account/profile',
        method: 'get'
    })
}

export function getSaving() {
    // 币种状态：1=正常，2=已售完，已售完的不可继续购买
    return request({
        url: '/api/v1/saving/list',
        method: 'get'
    })
}

export function savingCreate(data) {
    // 需要参数： { id: xxx, amount: 100, numDays: xxx } （id：币种数字编号，amount：购买数量，numDays：存期（0表示活期））
    return request({
        url: '/api/v1/saving/create',
        method: 'post',
        data
    })
}
export function savingOrders() {
    return request({
        url: '/api/v1/saving/orders',
        method: 'get'
    })
}


// until参数是数据库的一个自增参数，第一次调用可以用 0x7fffffff，后续分页可以用上一页最后一条的id
export function savingHistory(id = 0x7fffffff) {
    return request({
        url: '/api/v1/saving/history?until=' + id,
        method: 'get'
    })
}

export function savingRedeem(data) {
    return request({
        url: '/api/v1/saving/redeem',
        method: 'post',
        data
    })
}