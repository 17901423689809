<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="home_quotes">
                    <loading-indicator v-if="!symbols" />
                    <template v-else>
                        <symbol-list ref="sym_list" :symbols="symbols" :typeFilter="3"
                            @symbol-selected="gotoTradePage" />
                    </template>

                    <div class="text-center">
                        <div class="see-more">
                            <router-link to="/quotes">
                                {{ $t('home.label_more') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.see-more {
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    border: 1px solid #BAB9D0;
    color: #000000;
    width: 150px;
    border-radius: 40px;
    display: inline-block;
    margin: 20px 0;
}

.home_quotes {
    background: #fff;
    color: #000;
}

hr {
    background-color: var(--divider-secondary);
    opacity: 0.1;
}
@media(max-width:1024px){
    .see-more {
    text-align: center;
    font-size: 20px;
    padding: 10px 20px;
    border: 1px solid #BAB9D0;
    color: #000000;
    width: 40%;
    border-radius: 40px;
    display: inline-block;
    margin: 20px 0;
}
}
</style>

<script>
import SymbolList from './SymbolList.vue';

export default {
    components: { SymbolList },

    props: ['symbols'],

    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        getVisibleSids: function () {
            return this.symbols ? this.$refs.sym_list.getVisibleSids() : [];
        }
    }
};
</script>