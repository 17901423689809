<template>
    <section class="page help-page">
        <post-item-component :id="id" />
    </section>
</template>

<script type="text/javascript">
import PostItemComponent from '../../Home/Components/PostItem.vue';
export default {
    components: { PostItemComponent },
    props: ['id']
};
</script>