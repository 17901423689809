<template>
    <div class="container">
        <template v-if="!completed">
            <div class="row">
                <div class="col">
                    <label>{{ $t('boption.label_order_create_time') }}</label> {{ new Date(order.timeCreated).formatDateTime(true) }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>{{ $t('boption.label_order_create_price') }}</label> {{ order.createPrice.toFixed(order.pricePrecision) }}
                </div>
                <div class="col">
                    <label>{{ $t('boption.label_order_current_price') }}</label> {{ isNaN(current_price) ? '--' : current_price.toFixed(order.pricePrecision) }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>{{ $t('boption.label_order_direction') }}</label>
                    <span v-if="order.buyUp" class="color-up">{{ $t('boption.label_buy_up') }}</span>
                    <span v-else class="color-down">{{ $t('boption.label_buy_down') }}</span>
                </div>
                <div class="col">
                    <label>{{ $t('boption.label_order_estimated_profit') }}</label> <span v-bind:class="profitCssClass">{{ profitVal }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" v-bind:style="'width: ' + progress + '%'" aria-valuemin="0" aria-valuemax="100">{{ remainingSeconds }}</div>
                    </div>
                </div>
            </div>
        </template>

        <!-- display order result here -->
        <template v-else>
            <div class="row" v-if="!completedOrder">
                <div class="col">
                    <loading-indicator />
                </div>
            </div>
            <div class="row" v-else>
                <div class="col">
                    <order-details :order="completedOrder" />
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>
.container {
    line-height: 1.8;
    color: #212529;
}
.progress {
    height: 2rem;
    font-size: 1rem;
    margin: 1rem 0 2rem 0;
}
label {
    font-size: 0.85rem;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: #666;
}
</style>

<script>
import OrderDetails from './OrderDetails.vue';

export default {
    components: { OrderDetails },

    props: ['order', 'current_price'],

    data() {
        return {
            startTS: 0,
            progress: 0,
            remainingSeconds: '--',
            destroyed: false,

            // Display the result of the completed order.
            completed: false,
            completedOrder: null
        };
    },

    computed: {
        profitCssClass: function () {
            const p = this.current_price;
            if (isNaN(p) || p === this.order.createPrice) return '';

            let flag = p > this.order.createPrice;
            if (!this.order.buyUp) flag = !flag;

            return flag ? 'color-up' : 'color-down';
        },

        profitVal: function () {
            const p = this.current_price;
            if (isNaN(p) || p === this.order.createPrice) return '--';

            let flag = p > this.order.createPrice;
            if (!this.order.buyUp) flag = !flag;

            return flag ? (this.order.amount * this.order.profitRate).toFixed(2) : -this.order.amount.toFixed(2);
        }
    },

    created() {
        // Validate the required properties
        const d = this.order;
        // const sym = this.symbol;

        // Sample data for an order response:
        // {"data":{"orderId":"140348156001500000","timeCreated":1632892512247,"symbolName":"BTC","symbolId":201,"duration":30,"buyUp":true,"createPrice":9999.00000000,"closePrice":0.00000000,"amount":200.0,"profit":0.00000000,"profitRate":0.80,"fee":0.00000000,"status":1,"errorCode":0,"timeClosing":1632892542208,"remainingSeconds":29.933124,"pricePrecision":4,"currency":"USDT"},"errcode":0}
        if (!d || typeof d.duration !== 'number') {
            throw new Error('Requires a valid order property.');
        }

        // if (!sym || !sym.metadata) {
        //     throw new Error('Requires a valid symbol property.');
        // }

        this.startTS = new Date().valueOf();
        this.updateProgress();
    },

    beforeDestroy() {
        this.destroyed = true;
        console.log('## Destroy the order progress component.');
    },

    methods: {
        updateProgress: function () {
            const self = this;
            if (this.destroyed === false) {
                const leftSecs = Math.max(0, self.order.remainingSeconds - (new Date() - self.startTS) / 1000);
                this.progress = (1 - leftSecs / this.order.duration) * 100;
                this.remainingSeconds = leftSecs.toFixed(2) + 's';

                if (leftSecs > 0) {
                    // Do not update the progress when it's already completed.
                    setTimeout(() => self.updateProgress(), 17);
                } else {
                    this.completed = true;
                    setTimeout(() => {
                        self.readCompletedOrderAsync();
                    }, 500);
                }
            }
        },

        readCompletedOrderAsync: async function () {
            if (this.destroyed === false) {
                const self = this;

                // Read order status.
                const json = await $.callPostApi(this, '/api/v1/boptionorder/status?id=' + encodeURIComponent(this.order.orderId));
                if (json && json.errcode === 0) {
                    const entity = json.data;
                    if (entity.status === 4) {
                        // 4: completed
                        self.notifyCompleted();
                        this.completedOrder = entity;
                    } else {
                        // Retry in 0.5 second
                        setTimeout(() => {
                            self.readCompletedOrderAsync();
                        }, 500);
                    }
                }
            }
        },

        notifyCompleted: function () {
            // Notify that the order has been completed.
            this.$emit('completed', this.order.currency);
        }
    }
};
</script>