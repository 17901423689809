<template>
    <section>
        <table class="table list-table stock-list-table text-sm">
            <thead>
                <tr>
                    <th>{{ $t('balance.title_time') }}</th>
                    <th>{{ $t('balance.title_balance') }}</th>
                    <th>{{ $t('balance.title_commissions') }}</th>
                    <th>{{ $t('balance.title_description') }}</th>
                </tr>
            </thead>
            <tbody v-if="records">
                <tr v-for="rec in records" v-bind:key="rec.id">
                    <td>{{ new Date(rec.timeCreated).formatDateTime() }}</td>
                    <td>{{ rec.currentBalance }}</td>
                    <td>
                        <span v-if="rec.balanceChange >= 0" class="color-up">+{{ rec.balanceChange }}</span>
                        <span v-else class="color-down">{{ rec.balanceChange }}</span>
                    </td>
                    <td>{{ rec.description && (rec.type === 20 || rec.type === 26) ? rec.description :
                            translate(rec.type, rec.description) }}</td>
                </tr>
            </tbody>
        </table>
        <div v-if="hasMoreRecords"  class="button">
            <a href="javascript:;" v-if="prevUids.length > 0" class="btn btn-default btn-block text-muted" v-on:click="goPrevPageAsync()">{{ $t('balance.label_prev_page') }}</a>
            <a href="javascript:;" class="btn btn-default btn-block text-muted" v-on:click="queryData()">{{ $t('balance.label_next_page') }}</a>
        </div>

        <!-- status indicator -->
        <loading-indicator v-if="!records" />
        <div v-else-if="records.length === 0" class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
    </section>
</template>



<style scoped>
.btn {
    margin: 1rem 0;
}
.table td,
.table th {
    padding-left: 2px;
    padding-right: 2px;
}
.button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.button .btn-block {
    width: inherit;
}
</style>

<script>
const bct_map = {};
export default {
    data() {
        return {
            records: null,
            prevUids: [],
            hasMoreRecords: false,
            until_uid: 0
        };
    },

    created() {
        $(this.sysconfig.bcts).each((_, val) => {
            bct_map[val] = true;
        });

        this.queryData();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Query records
        ///////////////////////////////////////////////////////////////////////////////////////////
        queryData: function () {
            const self = this;
            // Remember the id of the first record in the current page.
            if (this.records && this.records.length > 0) {
                this.prevUids.push(this.records[0].uid + 1); // +1: because the query will exclude the specified uid itself.
            }
            self.records = null;

            $.callPostApi(self, '/api/v1/finance/history?token=INCT&uid=' + encodeURIComponent(self.until_uid))
                .then((json) => {
                    self.records = json.data;
                    if (json.data && json.data.length) {
                        self.until_uid = json.data[json.data.length - 1].uid;
                    }
                    self.hasMoreRecords = json.data.length === json.page_size;
                })
                .catch((err) => {
                    console.error(`ERROR: ${err}`);
                    $.top_error(self.$t('general.http_error'));
                });
        },
        translate(type, desp) {
            if (type < 100) {
                return this.$t(bct_map[type] === true ? 'bct.' + type : 'bct.others');
            } else {
                return (this.sysconfig.customBcts[this.lang] || {})[type] || desp;
            }
        },
        goPrevPageAsync: async function () {
            const self = this;
            if (this.prevUids.length > 0) {
                this.records = null;

                const uid = this.prevUids.pop();
                if (!isNaN(uid) && uid > 0) {
                    try {
                        const json = await $.callPostApi(this, '/api/v1/finance/history?token=INCT&uid=' + encodeURIComponent(self.until_uid));
                        if (json.errcode === 0) {
                            this.records = json.data;
                            if (json.data && json.data.length) {
                                this.until_uid = json.data[json.data.length - 1].uid;
                            }
                            this.hasMoreRecords = json.data.length === json.page_size;
                        }
                    } catch (err) {
                        console.error(`ERROR: ${err}`);
                        $.top_error(this.$t('general.http_error'));
                    }
                }
            }
        },
    }
};
</script>