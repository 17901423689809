<template>
    <section class="page help-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1 class="page-title">{{ $t(metadata.title_key) }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <loading-indicator v-if="!content" />
                    <pre class="agreement" v-html="content"></pre>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const supported_keys = Object.freeze({
    terms: { query: '服务条款', title_key: 'home.footer_terms' },
    disclaimer: { query: '风险披露', title_key: 'home.footer_disclaimer' },
    privacy: { query: '隐私条款', title_key: 'home.footer_privacy' }
});

export default {
    props: ['id'],

    data: function () {
        return { content: null, metadata: null };
    },

    created() {
        this.getContentAsync(this.id);
    },

    methods: {
        getContentAsync: async function (id) {
            if (id) {
                id = id.toLowerCase();
            }
            const meta = supported_keys[id];
            if (!meta) {
                this.$router.push('/notfound');
            } else {
                this.metadata = meta;

                const req_url = '/api/v1/help?id=' + encodeURIComponent(meta.query) + '&lang=' + encodeURIComponent(this.$i18n.locale);
                const json = await $.callGetApi(this, req_url);
                if (json) {
                    if (json.errcode === 0 && json.data) {
                        this.content = json.data;
                    } else {
                        this.content = this.$t('general.no_data');
                    }
                }
            }
        }
    },

    watch: {
        $route() {
            this.getContentAsync(this.id);
        },

        /**
         * Update notice content after the language is changed.
         */
        '$i18n.locale': function () {
            this.getContentAsync(this.id);
        }
    }
};
</script>