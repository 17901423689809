<template>
    <section class="page help-page support-iframe">
        <section class="pro_layout">
            <post-item-component v-bind:id="id" />
        </section>

        <ext-resource-i-frame />
    </section>
</template>

<script type="text/javascript">
import PostItemComponent from './Components/PostItem.vue';
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';

export default {
    components: { PostItemComponent, ExtResourceIFrame },
    props: ['id']
};
</script>