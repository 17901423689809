/**
 * base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
 * @param {*} dataurl 
 * @returns 
 */
function dataURLtoFile(dataurl) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];

    // suffix是该文件的后缀
    let suffix = mime.split('/')[1];

    // atob 对经过 base-64 编码的字符串进行解码
    let bstr = atob(arr[1]);

    // n 是解码后的长度
    let n = bstr.length;
    console.log(`# Compressed length: ${(n / 1024).toFixed(3)}KB`);

    // Uint8Array 数组类型表示一个 8 位无符号整型数组 初始值都是 数子0
    let u8arr = new Uint8Array(n);

    // charCodeAt() 方法可返回指定位置的字符的 Unicode 编码。这个返回值是 0 - 65535 之间的整数
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
    // 第三个参数是 要放到文件中的内容的 MIME 类型
    return new File([u8arr], `file.${suffix}`, { type: mime });
}


/**
 * 压缩图片
 * @param {*} file The file to compress. 
 */
function compressImg(file) {
    let fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(3)
    console.log(`# Orig file size: ${fileSize}M`);

    let read = new FileReader();
    read.readAsDataURL(file);

    return new Promise(function (resolve, reject) {
        read.onload = function (e) {
            let img = new Image();
            img.src = e.target.result;

            img.onload = function () {
                //默认按比例压缩
                // let w = this.width,
                //     h = this.height

                // The maximum height and width for the compressed image.
                const maxWidth = 1600;
                let widthRatio = Math.min(maxWidth / this.width, 1) // 计算宽度比例
                let heightRatio = Math.min(maxWidth / this.height, 1) // 计算高度比例

                let ratio = Math.min(widthRatio, heightRatio) // 取较小的比例作为最终比例

                let w = this.width * ratio // 根据比例调整画布尺寸
                let h = this.height * ratio

                //生成canvas
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');

                // 创建属性节点
                canvas.setAttribute('width', w)
                canvas.setAttribute('height', h)
                ctx.drawImage(this, 0, 0, w, h)

                /* 
                根据图片大小需要压缩
                if (fileSize < 0.5) {
                  //如果图片小于500k 那么不执行压缩操作
                  resolve(file)
                  return
                } else if (fileSize >= 0.5 && fileSize < 1) {
                  //如果图片大于500k并且小于1M 那么压缩0.5
                  base64 = canvas.toDataURL(file['type'], 0.5)
                } else {
                  //如果图片超过1m 那么压缩0.2
                  base64 = canvas.toDataURL(file['type'], 0.2)
                }
                // base64编码转成file类型文件
                files = dataURLtoFile(base64) //如果后台接收类型为base64的话这一步可以省略 
                */


                // UPDATE:
                // No need to upload a image with high quality.
                let encoderOptions = 0.25;
                let base64 = canvas.toDataURL('image/jpeg', encoderOptions);

                // remove the temporary canvas.
                canvas.remove();

                // base64编码转成file类型文件
                let createdFile = dataURLtoFile(base64) //如果后台接收类型为base64的话这一步可以省略

                // 返回file对象
                resolve(createdFile);
            };

            img.onerror = function () {
                reject('Invalid image.');
            }
        }
    })
}


export default {
    compressImg
}
