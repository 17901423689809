<template>
    <div class="posts">
        <div class="container" v-if="posts && posts.length > 0">
            <div class="row">
                <div class="col">
                    <h1 class="page-part-title mt-4">{{ $t('home.label_beginner_title') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 post" v-for="(post, index) in posts" :key="post.id" :class="{ 'd-md-none d-lg-block': index >= 3 }">
                    <router-link :to="'/post/' + post.id" class="text-start">
                        <img :src="post.imageUrl" v-if="post.imageUrl" class="img-fluid" />
                        <div class="post-title">{{ post.title }}</div>
                        <div class="post-brief" v-if="post.brief">{{ post.brief }}</div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.page-part-title {
    color: #c99400;
    font-size: 1.5rem;
}

.posts {
    background: #f8f9fb;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.post {
    padding-top: 1rem;
    text-align: center;
}
.post .img-fluid {
    width: 100%;
}
.post .post-title {
    color: #1f3f59;
    margin: 0.5rem 0;
}
.post .post-brief {
    color: #8c9fad;
}
</style>

<script>
export default {
    props: ['posts']
};
</script>