<template>
    <section class="main">
        <section class="page">
            <section class="pro_layout">
                <div class="page-part">
                    <div class="container compact-cols">
                        <div v-if="!posts" class="row">
                            <div class="col">
                                <loading-indicator />
                            </div>
                        </div>
                        <div v-else class="article-list">
                            <div v-if="posts.length === 0" class="row">
                                <div class="col">
                                    <div class="no-data-indicator">{{ $t('general.no_data') }}</div>
                                </div>
                            </div>
                            <div v-for="post in posts" v-bind:key="post.id" class="row">
                                <div class="col">
                                    <router-link :to="{ name: 'post', params: { id: post.id } }">
                                        <h2>{{ post.title }}</h2>

                                        <div v-if="post.brief">
                                            {{ post.brief }}
                                        </div>
                                    </router-link>
                                    <div class="help-block">
                                        <span class="author">{{ post.author }}</span> <span class="date">{{ new Date(post.timePublished).formatDateTime() }}</span>
                                    </div>
                                </div>
                                <div class="col-auto" v-if="post.imageUrl"><img v-bind:src="post.imageUrl" v-bind:title="post.title" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </section>
</template>
<script type="text/javascript">
export default {
    data() {
        return { posts: null };
    },

    created() {
        this.getKnowledges();
    },

    methods: {
        getKnowledges: function () {
            const self = this;

            self.$http
                .get(g_server_root + '/api/v1/knowledges')
                .then((resp) => {
                    if (resp.data.errcode === 0) {
                        self.posts = resp.data.data;
                    } else {
                        console.error('Failed to read posts: ' + resp.data.errmsg);
                    }
                })
                .catch((err) => {
                    console.error(`Error: ${err}`);
                });
        }
    }
};
</script>