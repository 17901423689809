<template>
    <div class="intro">
        <div class="pc-container">
            <div class="container">
                <div class="tab-intro">
                </div>
                <div class="news-intro">
                    <div class="list-box">
                        <div class="list">
                            <div class="img1">
                                <div class="news-title-pc">
                                    {{ $t("v42.news_title1") }}
                                </div>
                                <div class="news-title-pc">
                                    {{ $t("v42.news_desp1") }}
                                </div>
                                <div class="news-desp-pc">
                                    {{ $t("v42.news_desp11") }}
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img2">
                                <div class="news-title-pc">
                                    {{ $t("v42.news_title2") }}
                                </div>
                                <div class="news-title-pc">
                                    {{ $t("v42.news_desp2") }}
                                </div>
                                <div class="news-desp-pc">
                                    {{ $t("v42.news_desp22") }}
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img3">
                                <div class="news-title-pc">
                                    {{ $t("v42.news_title3") }}
                                </div>
                                <div class="news-desp-pc">
                                    {{ $t("v42.news_desp3") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="news-intro-mb">
                    <div class="list-box">
                        <div class="list">
                            <div class="img4">
                                <div class="news-title-mb">
                                    {{ $t("v42.news_title1") }}
                                </div>
                                <div class="news-title-mb">
                                    {{ $t("v42.news_desp1") }}
                                </div>
                                <div class="news-desp-mb">
                                    {{ $t("v42.news_desp11") }}
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img5">
                                <div class="news-title-mb">
                                    {{ $t("v42.news_title2") }}
                                </div>
                                <div class="news-title-mb">
                                    {{ $t("v42.news_desp2") }}
                                </div>
                                <div class="news-desp-mb">
                                    {{ $t("v42.news_desp22") }}
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img6">
                                <div class="news-title-mb">
                                    {{ $t("v42.news_title3") }}
                                </div>
                                <div class="news-desp-mb">
                                    {{ $t("v42.news_desp3") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        securityList: {
            type: Array,
            default: () => {
                return []
            }
        },
        otcList: {
            type: Array,
            default: () => {
                return []
            }
        },
        introList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            fileName: 'dist2',
            carouselList: [],
            activeName: 'first',
            introSwitch: 1
        }
    },
}
</script>
<style lang="less" scoped>
.tab-intro {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    margin-top: 100px;

    .left {
        margin-left: 105px;
        margin-right: 155px;
    }

    .right {
        padding-top: 183px;

        .title {
            font-size: 46px;
            line-height: 56px;
            font-weight: 600;
            color: var(--text-title);
            margin-bottom: 100px;
        }
    }
}

.news-intro {
    margin-bottom: 100px;

    .list-box {
        justify-content: space-between;
        display: flex;

        .list {
            margin-right: 19px;

            &:last-child {
                margin-right: 0;
            }
        }

        .news-desp-pc {
            font-size: 15px;
            padding-top: 15px;
            padding-left: 20px;
        }

        .news-title-pc {
            font-size: 25px;
            padding-top: 15px;
            padding-left: 20px;
            font-weight: 700;
        }

        .img1 {
            margin-bottom: 24px;
            width: 388px;
            height: 242px;
            background-size: cover;
            background-position: center;
            background-image: url('@/assets/images/v4.4/intro-de-1st.png');
        }

        .img2 {
            margin-bottom: 24px;
            width: 388px;
            height: 242px;
            background-size: cover;
            background-position: center;
            background-image: url('@/assets/images/v4.4/intro-de-2nd.jpg');
        }

        .img3 {
            margin-bottom: 24px;
            width: 388px;
            height: 242px;
            background-size: cover;
            background-position: center;
            background-image: url('@/assets/images/v4.4/intro-de-3rd.jpg');
        }

        .desp {
            .t {
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                margin-bottom: 12px;
            }

            .p {
                line-height: 18px;
                color: var(--text-desp);
            }
        }
    }
}

.news-intro-mb {
    display: none;
}

@media (max-width: 1024px) {
    .tab-intro {
        flex-direction: column-reverse;
        margin: 30px 0;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;

        .left {
            flex: 1;
            margin: 0;

            img {
                display: block;
                width: 210px;
                margin: 0 auto;
            }
        }

        .right {
            margin-bottom: 50px;
            border-radius: 16px;
            padding-top: 0;
            width: 100%;

            .title {
                text-align: center;
                font-size: 23px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 0;
                margin-bottom: 32px;
            }

            .tabs-box {
                width: 274px;
                margin: 0 auto;

                /deep/.el-tabs__header {
                    margin-bottom: 28px;

                    .el-tabs__nav-wrap {
                        padding: 0;
                        border-radius: 16px;
                    }

                    .el-tabs__nav {
                        height: 32px;
                        width: 274px;

                        .el-tabs__item {
                            font-size: 12px;
                        }
                    }

                    .el-tabs__active-bar {
                        height: 28px;
                        top: 2px;
                        left: 2px;
                        border-radius: 14px;
                    }
                }

                /deep/.el-tabs__content {
                    .el-tab-pane {
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .news-intro {
        margin-bottom: 50px;

        .list-box {
            display: block;

            .list {
                margin-bottom: 50px;
                margin-right: 0;

                .img {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 343px;
                }
            }

            .desp {
                text-align: center;

                .t {
                    font-size: 14px;
                    margin-bottom: 16px;
                }

                .p {
                    margin-bottom: 50px;
                    font-size: 12px;
                }
            }
        }
    }

    .news-intro {
        display: none;
    }

    .news-intro-mb {
        display: block;
        margin-bottom: 50px;

        .list-box {
            display: flex;
            justify-content: space-between;

            .list {
                margin-bottom: 50px;
                margin-right: 0;
                width: 30%;

                .news-desp-mb {
                    font-size: 7.5px;
                    padding-top: 7.5px;
                    padding-left: 10px;
                }

                .news-title-mb {
                    font-size: 12.5px;
                    padding-top: 7.5px;
                    padding-left: 10px;
                    font-weight: 700;
                }

                .img4 {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 194px;
                    height: 121px;
                    background-size: cover;
                    background-position: center;
                    background-image: url('@/assets/images/v4.4/intro-de-1st.png');
                }

                .img5 {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 194px;
                    height: 121px;
                    background-size: cover;
                    background-position: center;
                    background-image: url('@/assets/images/v4.4/intro-de-2nd.jpg');
                }

                .img6 {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 194px;
                    height: 121px;
                    background-size: cover;
                    background-position: center;
                    background-image: url('@/assets/images/v4.4/intro-de-3rd.jpg');
                }
            }

            .desp {
                text-align: center;

                .t {
                    font-size: 14px;
                    margin-bottom: 16px;
                }

                .p {
                    margin-bottom: 50px;
                    font-size: 12px;
                }
            }
        }
    }
}

@media (max-width: 760px) {
    .news-intro-mb {
        display: block;
        margin-bottom: 50px;

        .list-box {
            display: flex;
            justify-content: space-between;

            .list {
                margin-bottom: 50px;
                margin-right: 0;
                width: 100%;
                .news-desp-mb {
                    font-size: 3px;
                    padding-top: 7.5px;
                    padding-left: 10px;
                }

                .news-title-mb {
                    font-size: 6px;
                    padding-top: 7.5px;
                    padding-left: 10px;
                    font-weight: 700;
                }
                .img4 {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 100px;
                    height: 65px;
                    background-size: cover;
                    background-position: center;
                    background-image: url('@/assets/images/v4.4/intro-de-1st.png');
                }

                .img5 {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 100px;
                    height: 65px;
                    background-size: cover;
                    background-position: center;
                    background-image: url('@/assets/images/v4.4/intro-de-2nd.jpg');
                }

                .img6 {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 100px;
                    height: 65px;
                    background-size: cover;
                    background-position: center;
                    background-image: url('@/assets/images/v4.4/intro-de-3rd.jpg');
                }
            }

            .desp {
                text-align: center;

                .t {
                    font-size: 14px;
                    margin-bottom: 16px;
                }

                .p {
                    margin-bottom: 50px;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
