
/**
 * ============================================================================
 * The home_symbol class
 */
export function SymbolInfo(sym) {
    const meta = {
        id: sym.id,
        symbol: sym.symbol,
        name: sym.name,
        prompted: sym.prompted,
        type: sym.type,
        pricePrecision: sym.pricePrecision,
        volumePrecision: sym.volumePrecision,
        baseSymbol: sym.baseSymbol,
        quoteSymbol: sym.quoteSymbol,
        testOnly: sym.testOnly === true,
        svgIcon: sym.svgIcon
    };
    if (meta.type !== 1) {
        let pos = meta.symbol.indexOf('_');
        if (pos < 0) {
            pos = meta.symbol.indexOf('/');
        }

        if (pos > 0) {
            meta.base_symbol = meta.symbol.substring(0, pos);
            meta.quote_symbol = meta.symbol.substring(pos + 1);
        }
    }
    this.metadata = Object.freeze(meta);

    this.day_vol = '--';
    this.price = '--';
    this.price_val = NaN;
    this.price_change = '--';
    this.price_change_pct = '--';
    this.up = true;

    this.day_low = '--';
    this.day_high = '--';

    const sid = sym.id;
    const price_precision = sym.pricePrecision;
    const vol_precision = sym.volumePrecision;

    this.update = function (stats) {
        // update data for the current symbol
        this.price_val = stats.c;
        this.price = stats.c.toFixed(price_precision);
        this.day_low = stats.dl.toFixed(price_precision);
        this.day_high = stats.dh.toFixed(price_precision);
        this.day_vol = stats.dv.toFixed(vol_precision);

        let diff = stats.c - stats.ds; // close price - open price
        let pct = (diff / stats.ds) * 100;

        let s = diff.toFixed(price_precision);
        this.price_change = diff >= 0 ? '+' + s : s;
        this.price_change_pct = isNaN(pct) || !isFinite(pct) ? '' : pct.toFixed(2) + '%';
        this.up = diff >= 0;
    };

    this.updateRtqs = function (quote) {
        // e.g.
        // {t: 1644237773, id: 201, v: [42380.87, 42657.92, 41638.4, 42929.99, 3.56, 3378.6]}
        //
        // in which, v contains:
        // [day open, close, day low, day high, vol, day vol]
        if (quote.id === sid) {
            this.update({
                ds: quote.v[0],
                c: quote.v[1],
                dl: quote.v[2],
                dh: quote.v[3],
                dv: quote.v[5]
            });
        }
    };

    this.getRoutePath = function () {
        const TYPE_MICRO_ORDERS = 1;
        const TYPE_FUTURES = 2;
        const TYPE_EXCHANGE = 3;

        switch (sym.type) {
            case TYPE_MICRO_ORDERS:
                return '/boption/' + sym.symbol.toLowerCase();
            case TYPE_FUTURES:
                return '/futures/' + sym.symbol.toLowerCase();
            case TYPE_EXCHANGE:
                return '/exchange/' + sym.symbol.toLowerCase();
        }

        return null;
    };
};