<template>
    <section class="page settings-page">
        <div class="container">
            <h1>{{ $t('helps.title') }}</h1>
            <div v-if="!posts" class="row">
                <div class="col">
                    <loading-indicator />
                </div>
            </div>
            <div v-else class="article-list">
                <div v-if="posts.length === 0" class="row">
                    <div class="col">
                        <div class="help-block">{{ $t('general.no_data') }}</div>
                    </div>
                </div>
                <div v-for="post in posts" v-bind:key="post.id" class="row">
                    <div class="col">
                        <router-link :to="{ name: 'help', params: { id: post.id } }">
                            {{ post.title }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script type="text/javascript">
export default {
    data() {
        return { posts: null };
    },

    created() {
        this.getHelpersAsync();
    },

    methods: {
        getHelpersAsync: async function () {
            try {
                const json = await $.callGetApi(this, '/api/v1/help-articles');
                if (json && json.errcode === 0) {
                    this.posts = json.data;
                }
            } catch (err) {
                console.error(`Error: ${err}`);
            }
        }
    }
};
</script>