<template>
    <div class="list">
        <div class="table mobile-table" v-if="type === 1">
            <div class="body">
                <div class="card-item" v-for="(item, index) in tableData" :key="index">
                    <div>
                        <div class="label">{{ $t('coin_treasure.currency') }}</div>
                        <div class="val">{{ item.token }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.lump_sum') }}</div>
                        <div class="val">{{ item.amount + item.totalInterests + item.totalExtraInterests }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.interest_accruing') }}</div>
                        <div class="val"> {{ item.amount }}</div>
                    </div>
                    <!-- <div>
                        <div class="label">{{$t('coin_treasure.apply_today')}}</div>
                        <div class="val"> {{ item.amount }}</div>
                    </div> -->
                    <div>
                        <div class="label">{{ $t('coin_treasure.estimated_annual') }}</div>
                        <div class="val"><span :class="item.interestRate > 0 ? 'up' : 'down'">{{
                            Math.round(item.interestRate * 10000) / 100
                        }}%</span></div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.level_reward') }}</div>
                        <div class="val"> {{ item.totalExtraInterests }}</div>
                    </div>
                    <div>
                        <div></div>
                        <div class="val" v-if="item.status === 1">
                            <button class="btn btn-primary" @click="redeemFunc(item)">{{ $t('coin_treasure.early_redemption') }}</button>
                        </div>
                        <div class="val" v-if="item.status === 2"> {{ $t('coin_treasure.redemption') }}</div>
                        <div class="val" v-if="item.status === 3"> {{ $t('coin_treasure.redeemed') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table mobile-table" v-else>
            <div class="body">
                <div class="card-item" v-for="(item, index) in tableData" :key="index">
                    <div>
                        <div class="label">{{ $t('coin_treasure.currency') }}</div>
                        <div class="val">{{ item.token }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.subscription_date') }}</div>
                        <div class="val">{{ new Date(item.timeCreated).formatDate() }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.lump_sum') }}</div>
                        <div class="val">{{ item.amount + item.totalInterests + item.totalExtraInterests }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.estimated_annual') }}</div>
                        <div class="val"><span :class="item.interestRate > 0 ? 'up' : 'down'">{{
                            Math.round(item.interestRate * 10000) / 100
                        }}%</span></div>
                    </div>
                    <!-- <div>
                        <div class="label">最后计息日</div>
                        <div class="val">{{ item.token }}</div>
                    </div>
                    <div>
                        <div class="label">计息天数</div>
                        <div class="val">{{ item.token }}</div>
                    </div> -->
                    <div>
                        <div class="label">{{ $t('coin_treasure.cumulative_income') }}</div>
                        <div class="val">{{ item.totalInterests }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.level_reward') }}</div>
                        <div class="val">{{ item.token }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.maturity_date') }}</div>
                        <div class="val">{{ new Date(item.timeCreated + (item.numDays * 60 * 60 * 24 *
                            1000)).formatDate()
                        }}</div>
                    </div>
                    <div>
                        <div class="label">{{ $t('coin_treasure.state') }}</div>
                        <div class="val">{{ statusToText(item.status) }}</div>
                    </div>
                    <div>
                        <div class="label"></div>
                        <div class="val" v-if="item.status === 1">
                            <button class="btn btn-primary" @click="redeemFunc(item)">{{ $t('coin_treasure.early_redemption') }}</button>
                        </div>
                        <div class="val" v-if="item.status === 2"> {{ $t('coin_treasure.redemption') }}</div>
                        <div class="val" v-if="item.status === 3"> {{ $t('coin_treasure.redeemed') }}</div>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="isShow">
            <Dialog ref="earnModal" :data="data"></Dialog>
        </div>
    </div>
</template>

<script>

// import Coin from '../../Components/Coin.vue';
import Dialog from './RedeemDialog.vue';

export default {
    components: { Dialog },
    props: ['tableData', 'type'],
    data() {
        return {
            uid: null,
            amount: 0,
            data: {},
            isShow: false
        };
    },
    created() {
    },
    mounted() { },
    methods: {
        redeemFunc(data) {
            // this.uid = data.uid
            // this.amount= data.amount
            this.isShow = true
            this.data = data
            // 重新挂载组件
            this.$nextTick(() => {
                this.showDialog()
            })
        },
        hideModel() {
            // this.isShow = false
        },
        showDialog: function () {
            const modal = this.$refs.earnModal
            modal.showModal();
        },
        statusToText(status) {
            switch (status) {
                case 1:
                    return this.$t('coin_treasure.interest_accruing');;
                case 2:
                    return this.$t('coin_treasure.redemption');
                case 3:
                    return this.$t('coin_treasure.redeemed');
            }
            return '';
        }
    },
};
</script>
