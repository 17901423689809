<template>
    <div class="d-none iframe-container bg-body" ref="iframeContainer">
        <button type="button" aria-label="Close" class="btn-close btn-primary p-3" @click="closeIFrame"></button>
        <iframe name="3rdframe" src="about:blank" frameborder="0" referrerpolicy="same-origin">NO IFRAME SUPPORT</iframe>
    </div>
</template>


<style scoped>
.btn-close {
    position: absolute;
    z-index: 1000;
    right: 1rem;
    top: 1rem;
}

.iframe-container,
.iframe-container iframe {
    position: absolute;
    z-index: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.iframe-container iframe {
    width: 100%;
    height: 100%;
}
</style>

<script>
export default {
    methods: {
        closeIFrame: function () {
            $('.iframe-container iframe').attr('src', 'about:blank');
            $('.iframe-container').addClass('d-none');
        }
    }
};
</script>