<template>
    <div ref="earnModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ $t('coin_treasure.confirm_redemption') }}</div>
                </div>
                <div class="modal-body">
                    <div class="balance py-3">{{ $t('coin_treasure.redeemable_quantity') }} {{ amount }}</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-bs-dismiss="modal" @click="parentfunc">
                        {{ $t('general.cancel') }}
                    </button>
                    <button type="submit" class="btn btn-primary" :disabled="!amount && submitting" :class="{ 'btn-loading': submitting }" @click="confirm">
                        {{ $t('general.confirm') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { savingRedeem } from '../request';
import { Modal } from 'bootstrap';

export default {
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },

    data() {
        return {
            checkedStatus: false,
            amount: '',
            balance: 0,
            interestRates: '',
            dayList: [],
            numDays: '',
            submitting: false,

            // The confirmation popup modal
            popupModal: null
        };
    },

    mounted() {
        this.amount = this.data.amount;
        this.popupModal = new Modal(this.$refs.earnModal);
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        confirm() {
            let data = {
                id: this.data.uid
            }
            this.submitting = true
            savingRedeem(data).then(res => {
                this.submitting = false
                // const data = res.data.data
                const errcode = res.data.errcode
                if (errcode === 0) {
                    $.top_alert(this.$t('coin_treasure.redemption_succeeded'))
                } else {
                    $.top_error(res.data.errmsg)
                }
                // $(this.$refs.earnModal).modal('hide');
                this.popupModal.hide();
                this.$parent.$parent.getSavingOrder();
            })
        },
        parentfunc() {
            this.$parent.$parent.getSavingOrder();
            // $(this.$refs.earnModal).hide()
            this.popupModal.hide();

        },
        setMax() {
            this.amount = data.amount
        },
        showModal() {
            // const modal = bootstrap.Modal.getOrCreateInstance(this.$refs.earnModal);
            // modal.show();
            this.popupModal.show();
        },
    }
};
</script>
