<template>
    <section class="page balance-page">
        <section v-if="bcConfig" class="w-100">
            <div class="container assets-container px-3 py-5">
                <div class="assets-box">
                    <div class="assets-title-box">
                        <span class="assets-title">{{ $t('assets.title') }}</span>
                    </div>
                    <div class="assets-content">
                        <div class="assets-content-left">
                            <div class="assets-content-des">{{ $t('assets.title') }}</div>
                            <div class="assets-content-value">{{ balance_list[0].balance + ' ' +
                                balance_list[0].currency }}</div>
                        </div>
                        <div class="assets-content-right">
                            <div class="row balance-actions">
                                <div class="col">
                                    <router-link to="/finance/orders">
                                        {{ $t('balance.orders_title') }}
                                    </router-link>
                                </div>
                                <div class="col">
                                    <a href="javascript:;" @click="showExchangeModal">
                                        {{ $t('assets.label_exchange') }}
                                    </a>
                                </div>
                                <div class="col">
                                    <router-link to="/finance/history">
                                        {{ $t('balance.history_title') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- balance list here -->
                <balance-list-multi-coins v-if="bcConfig.supportMultiCoinDeposits === true" :balance_list="balance_list"
                    :futures_assets="futuresAssets" :bcConfig="bcConfig" />
                <balance-list v-else :balance_list="balance_list" :show_deposit_address="show_deposit_address"
                    :futures_assets="futuresAssets" :bcConfig="bcConfig" />
            </div>

            <futures-stats ref="futuresStats" @balance-updated="onFuturesStatsUpdated" />
            <asset-exchange-modal ref="exchangeModal" :balances="balance_list" :bonusToken="bonusToken"
                @balance-updated="onBalanceUpdated" />
        </section>
    </section>
</template>

<style scoped>
.page {
    background: #F6F6F7;
}

.top-title {
    margin: 80px 0 40px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 29px;
    color: #000000;
    line-height: 41px;
}

.rank {
    display: flex;
    justify-content: space-between;
}

.rank-content {
    background-color: #fff;
    padding: 15px 20px;
    width: 380px;
}

.rank-title-box {
    display: flex;
    justify-content: space-between;
}

.rank-item {
    display: flex;
    margin: 9px 0;
    align-items: center;
}

.rank-item>span {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    line-height: 21px;
    margin-right: 10px;
}

.rank-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    line-height: 20px;
}

.top-header {
    margin-bottom: 80px;
}

.assets-container {
    background-color: #fff;
    margin-bottom: 60px;
    margin-top: 60px;
}

.assets-title-box {
    border-bottom: 1px solid #D6D6D6;
}

.assets-title {
    background: #3E72ED;
    border: 1px solid #D6D6D6;
    padding: 18px 44px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 25px;
    width: auto;
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
}
.finance-history {
    background-color: #fff;
    border: 1px solid #3E72ED;
    color: #3E72ED;
}

.assets-content-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #969699;
    line-height: 24px;
}

.assets-content-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #242428;
    line-height: 24px;
    margin-top: 40px;
}

.assets-content {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.to-quotes {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #969699;
    line-height: 20px;
}

@media (max-width: 1024px) {
    .balance-actions {
        display: flex;
        flex-direction: column;
    }
}

.balance-actions .col>a {
    border-radius: 8px;
    border: 1px solid #437BED;
    padding: 10px 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #1673F9;
    line-height: 20px;
    background: #fff;
    white-space: nowrap;
}

.balance-actions .col:first-child>a {
    background: #437BED;
    color: #FFFFFF;
}

@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }

    .d-block {
        display: block !important;
    }

    .assets-content {
        display: block;
    }

    .assets-content-value {
        margin-top: 12px;
    }

    .assets-content-right {
        margin-top: 32px;
    }
}
</style>

<script>
import { getSymbolManagerAsync } from 'utilities/helper';
import { SymbolInfo } from 'utilities/SymbolInfo.js';
import AssetExchangeModal from './Components/AssetExchangeModal.vue';
import FuturesStats from '../Components/FuturesOpenOrderStats.vue';
import BalanceList from './BalanceComponents/_BalanceList-4.1.vue';
import BalanceListMultiCoins from './BalanceComponents/_BalanceList-MultiCoins.vue';
import PromptedSymbol from '../Home/Components/_PromptedSymbol.vue';

let g_assetTypes = [];
let g_assetTypeMap = {};
let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { AssetExchangeModal, FuturesStats, BalanceList, BalanceListMultiCoins, PromptedSymbol },

    data() {
        return {
            balance_list: null,
            bonusToken: null,
            futuresAssets: '--',

            // Config settings for blockchain
            bcConfig: null,

            // Indicates whether to display deposit addresses or not.
            show_deposit_address: false,
            rankList: null
        };
    },

    created() {
        this.initAsync();
        this.initPageAsync()
    },
    computed: {
        rankListHot() {
            if (this.rankList) {
                const list = this.rankList
                let arr = list.filter(function (s) {
                    return s.metadata.prompted && !s.metadata.testOnly;
                });

                return arr.splice(0, 4)
            }
        },
        rankListUp() {
            if (this.rankList) {
                const list = JSON.parse(JSON.stringify(this.rankList))
                let arr = list.filter(function (s) {
                    return s.metadata.prompted && !s.metadata.testOnly;
                });

                const arr1 = arr.sort((a, b) => { return b.price_change_pct.split('%')[0] - a.price_change_pct.split('%')[0] })
                return arr1.splice(0, 4)
            }
        },
        rankListDown() {
            if (this.rankList) {
                const list = JSON.parse(JSON.stringify(this.rankList))
                let arr = list.filter(function (s) {
                    return s.metadata.prompted && !s.metadata.testOnly;
                });
                const arr1 = arr.sort((a, b) => { return a.price_change_pct.split('%')[0] - b.price_change_pct.split('%')[0] })
                return arr1.splice(0, 4)
            }
        }
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.rankList = arr;

                // start to subscribe realtime quote data
                self.syncQuotes();
            }
        },
        initAsync: async function () {
            ///////////////////////////////////////////////////////////////////////////////////////
            // Get all asset types.
            const assetTypes = [];
            const assetTypeMap = {};

            // USDT and futures margin account will already be displayed at top
            assetTypes.push('USDT');
            assetTypes.push('FTUSDT');
            assetTypeMap['USDT'] = [];
            assetTypeMap['FTUSDT'] = [];

            // Then query blockchain config
            const resp = await $.callPostApi(this, '/api/v1/blockchain/config');
            if (!resp || !resp.data) {
                // exit for invalid config
                console.error('Invalid blockchain config.');
                return;
            }

            if (resp.data.supportedCoins) {
                $(resp.data.supportedCoins).each((index, item) => {
                    // Always use upper case
                    const upperCoin = item.currency.toUpperCase();

                    if (!assetTypeMap[upperCoin]) {
                        assetTypes.push(upperCoin);
                    }
                    assetTypeMap[upperCoin] = item;
                });
            }

            g_assetTypes = Object.freeze(assetTypes);
            g_assetTypeMap = Object.freeze(assetTypeMap);
            this.bonusToken = resp.data.bonusToken;
            this.bcConfig = Object.freeze(resp.data);

            ///////////////////////////////////////////////////////////////////////////////////////
            // Update balance list finally
            await this.syncBalancesAsync();
        },

        toggle_deposit: function () {
            this.show_deposit_address = !this.show_deposit_address;
        },

        syncBalancesAsync: async function () {
            const self = this;
            const balances = await $.callPostApi(this, '/api/v1/balance/list');

            const map = {};
            $(balances).each((index, raw_balance) => {
                map[raw_balance.currency] = new BalanceItem(raw_balance);
            });

            // Have configured tokens display at top
            const output = [];
            $(g_assetTypes).each((index, currency) => {
                let item = map[currency];
                if (!item) {
                    item = new BalanceItem({ currency: currency, balance: 0, frozen: 0 });
                } else {
                    delete map[currency];
                }
                output.push(item);
            });

            // Any remaining balance items?
            for (let currency in map) {
                output.push(map[currency]);
            }

            self.balance_list = output;
        },

        onBalanceUpdated: function () {
            const self = this;
            this.balance_list = null;

            setTimeout(() => {
                // Update balance, and resync open futures orders.
                self.syncBalancesAsync();
                self.$refs.futuresStats.reload();
            }, 500);
        },

        showExchangeModal: function () {
            this.$refs.exchangeModal.showModal();
        },

        /**
         * Update the available amount for the futures account.
         */
        onFuturesStatsUpdated: function () {
            const stats = this.$refs.futuresStats.getStats();
            this.futuresAssets = isNaN(stats.current_balance) ? '--' : stats.current_balance.toFixed(4);
        },
        syncQuotes: function () {
            const self = this;

            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const do_sync = function () {
                const sids = [];
                for (let sid in g_symbolMap) {
                    sids.push(sid);
                }

                if (sids.length > 0) {
                    // Read quotes from server
                    self.$http
                        .get(g_server_root + '/api/v1/quotation/latest?symbols=' + sids.join(','))
                        .then((json) => {
                            const quotes = json.data;
                            if (quotes.length > 0) {
                                for (let i = 0; i < quotes.length; i++) {
                                    const quote = quotes[i];
                                    const sym = g_symbolMap[quote.id];
                                    if (sym) {
                                        sym.update(quote);
                                    }
                                }
                            }
                        })
                        .then(() => {
                            if (g_destoryed === false) {
                                // always restart timer
                                let delay = 4000 + Math.round(Math.random() * 3000);
                                g_quoteTimerId = setTimeout(do_sync, delay);
                            }
                        });
                }
            };

            do_sync();
        }

    }
};

/**
 * BalanceItem
 */
function BalanceItem(balance) {
    // this.__raw_data = balance;

    // is the symbol associated to an exchange one?
    // const sym = _exchange_symbol_name_map[balance.currency];
    // const precision = sym ? sym.volumePrecision : 2;

    // hard-coded to use 4 precision
    const precision = 4;

    this.available = (balance.balance + balance.frozen).formatBalance(precision);
    this.balance = balance.balance.formatBalance(precision);
    this.frozen = (-balance.frozen).formatBalance(precision);
    // this.incentive = balance.incentive.floor(precision) * 1;

    this.display_name = balance.displayName || balance.currency;
    this.currency = balance.currency;
}
</script>