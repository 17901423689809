<template>
    <div v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block">
            <div class="market-wrap">
                <dl>
                    <dt>
                        <div>{{ $t('orders.label_symbol') }}</div>
                        <div>{{ $t('home.price') }}</div>
                        <div>{{ $t('home.24h_change') }}</div>
                        <div>{{ $t('home.24h_low') }}</div>
                        <div>{{ $t('home.24h_high') }}</div>
                        <div>{{ $t('home.symbol_action') }}</div>
                    </dt>
                    <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)">
                        <router-link :to="symbol.getRoutePath()">
                            <div class="text-uppercase d-flex align-items-center">
                                <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                <div>{{ symbol.metadata.name }}</div>
                            </div>
                            <div growing-ignore="true">${{ symbol.price }}</div>
                            <div growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.price_change_pct }}
                                </span>
                            </div>
                            <div growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.day_low }}
                                </span>
                            </div>

                            <div growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.day_high }}
                                </span>
                            </div>
                            <div class="buy"><span>Comprar</span></div>
                        </router-link>
                    </dd>
                </dl>
            </div>
        </section>
        <section class="homepage-market-sm d-lg-none d-md-none">
            <div class="title">
                <p class="text-left">{{ $t('orders.label_symbol') }}</p>
                <p class="text-center">{{ $t('home.price') }}</p>
                <p class="text-end">{{ $t('home.24h_change') }}</p>
            </div>
            <dl>
                <!-- <dd class="title">
                    <dl>{{ $t('orders.label_symbol') }}</dl>
                    <dl class="text-center">{{ $t('home.price') }}</dl>
                    <dl class="text-end">{{ $t('home.24h_change') }}</dl>
                </dd> -->
                <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)">
                    <dl>
                        <dt class="text-uppercase d-flex align-items-center token-name">
                            <div class="pe-2"><token-icon :symbol="symbol" /></div>
                            <div>{{ symbol.metadata.name }}</div>
                        </dt>
                        <!-- <dd growing-ignore="true">24H Vol {{ symbol.day_vol }}</dd> -->
                    </dl>
                    <dl>
                        <dt growing-ignore="true">{{ symbol.price }}</dt>
                        <dd growing-ignore="true">${{ symbol.price_change }}</dd>
                    </dl>
                    <div>
                        <span :class="symbol.up ? 'bg-up' : 'bg-down'" growing-ignore="true">{{ symbol.price_change_pct }}</span>
                    </div>
                </dd>
            </dl>
        </section>
    </div>
</template>
<style scoped>
@media screen and (min-width:768px) and (max-width:1024px) {
    .homepage-market-sm{
        display: block !important;
    }
    .homepage-market{
        display: none !important;
    }
}
.title {
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: space-between;
}
.title p{
    text-align: center;
    display:flex;
    align-items: end;
}
.title p.text-left {
    max-width: 140px;
    text-align: left;
    word-break:break-all;
}
.title p.text-end {
    text-align: right;
}
</style>
<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm'],

    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }

            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                return this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
            } else {
                // Return symbols for the specified type.

                return this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                });
            }
        }
    },

    methods: {
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },

        getVisibleSids: function () {
            const output = [];
            $(this.filteredSymbols).each((index, sym) => {
                output.push(sym.metadata.id);
            });

            return output;
        }
    }
};
</script>