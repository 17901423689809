<template>
    <div class="list">
        <div class="table mobile-table" v-if="type === 1">
            <div class="body">
                <div class="card-item" v-for="(item, index) in tableData" :key="index">
                    <div v-for="(t, k) in body" :key="k">
                        <div class="label" v-if="!t.title"></div>
                        <div class="label" v-else>{{ $t(t.title) }}</div>
                        <div class="val">
                            <span v-if="t.value == 'timeCreated'">
                                {{ new Date(item[t.value]).formatDate() }}
                            </span>
                            <span v-else-if="t.value == 'status'"> {{ statusToText(item.status) }}</span>
                            <!-- <span v-else-if="t.value == 'status' && item[t.value] === 1"><button class="btn btn-primary" @click="redeemFunc(item)">{{$t('coin_treasure.early_redemption')}}</button></span>
                            <span v-else-if="t.value == 'status' && item[t.value] === 2">{{$t('coin_treasure.redemption')}}</span>
                            <span v-else-if="t.value == 'status' && item[t.value] === 3">{{$t('coin_treasure.redeemed')}}</span> -->
                            <span v-else>{{ item[t.value] }}</span>
                        </div>
                        <!-- <div class="val" v-else-if="t.render">list</div> -->
                        <!-- <div class="val" v-else>{{ item[t.value] }}</div> -->

                    </div>
                </div>
            </div>

        </div>


        <div v-if="isShow">
            <Dialog ref="earnModal" :data="data"></Dialog>
        </div>
    </div>
</template>

<script>

// import Coin from '../../Components/Coin.vue';
import Dialog from './RedeemDialog.vue';

export default {
    components: { Dialog },
    props: ['tableData', 'type', 'body'],
    data() {
        return {
            uid: null,
            amount: 0,
            data: {},
            isShow: false
        };
    },
    created() {
    },
    mounted() { },
    methods: {
        redeemFunc(data) {
            // this.uid = data.uid
            // this.amount= data.amount
            this.isShow = true
            this.data = data
            // 重新挂载组件
            this.$nextTick(() => {
                this.showDialog()
            })
        },
        hideModel() {
            this.isShow = false
        },
        showDialog: function () {
            const modal = this.$refs.earnModal
            modal.showModal();
        },
        statusToText(status) {
            switch (status) {
                case 1:
                    return this.$t('coin_treasure.interest_accruing');
                case 2:
                    return this.$t('coin_treasure.redemption');
                case 3:
                    return this.$t('coin_treasure.redeemed');
            }
            return '';
        }
    },
};
</script>
