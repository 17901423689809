<template>
    <section class="prompted-markets" v-if="symbols && symbols.length > 0">
        <div class="prompted-inner d-flex flex-justify-center">
            <dl v-for="(symbol, index) in symbols" :key="index" @click="onSymbolClicked(symbol)">
                <dt>{{ symbol.metadata.name }}</dt>
                <dd growing-ignore="true" class="symbol-price">
                    {{ symbol.price }}
                </dd>
                <dd growing-ignore="true" :class="symbol.up ? 'up' : 'down'">{{ symbol.price_change_pct }}</dd>
            </dl>
        </div>
    </section>
</template>

<script>
export default {
    props: ['symbols'],

    methods: {
        onSymbolClicked: function (sym) {
            this.$emit('symbol-clicked', sym);
        }
    }
};
</script>