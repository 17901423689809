import { render, staticRenderFns } from "./RegionSelectorV2.vue?vue&type=template&id=8899b2c6&scoped=true&"
import script from "./RegionSelectorV2.vue?vue&type=script&lang=js&"
export * from "./RegionSelectorV2.vue?vue&type=script&lang=js&"
import style0 from "./RegionSelectorV2.vue?vue&type=style&index=0&id=8899b2c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8899b2c6",
  null
  
)

export default component.exports