<template>
    <section class="page startup-page support-iframe">
        <loading-indicator v-if="!token" />
        <section v-else class="pro_layout container">
            <h1 class="d-flex align-items-center">
                <div v-if="token.svgIcon" class="pe-2">
                    <startup-icon :token="token" />
                </div>
                <div>{{ token.title }}</div>
            </h1>

            <div class="startup-item">
                <div class="row">
                    <div class="col">
                        <pre class="text" v-html="token.shortDescription"></pre>
                        <div class="text-danger">{{ $t('startup.label_offer_price') }}: {{ token.offerPrice.toFixed(token.pricePrecision) * 1 }} USDT</div>
                        <template v-if="!token.hideSubscriptionPeriod">
                            <div class="text-danger">{{ $t('startup.label_start_time') }}: {{ new Date(token.startTime).formatDateTime() }}</div>
                            <div class="text-danger">{{ $t('startup.label_end_time') }}: {{ new Date(token.endTime).formatDateTime() }}</div>
                        </template>
                    </div>
                </div>

                <subscribe-token :token="token" />

                <div class="row">
                    <div class="col">
                        <h2>{{ $t('startup.label_full_desp') }}</h2>
                        <pre class="text" v-html="token.fullDescription"></pre>
                    </div>
                </div>
            </div>
        </section>

        <ext-resource-i-frame />
    </section>
</template>

<script>
import SubscribeToken from './Components/SubscribeToken.vue';
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import StartupIcon from '../Components/_StartupIcon.vue';

export default {
    components: { SubscribeToken, ExtResourceIFrame, StartupIcon },

    props: ['id'],

    data() {
        return { token: null };
    },

    mounted() {
        this.getTokenAsync(this.id);
    },

    methods: {
        getTokenAsync: async function (id) {
            const self = this;
            self.token = null;

            if (id) {
                const json = await $.callGetApi(self, '/api/v1/startup?id=' + encodeURIComponent(id));
                if (json && json.errcode === 0) {
                    self.token = Object.freeze(json.data);

                    Vue.nextTick(() => {
                        $.scanPopup();
                    });
                }
            }
        }
    },

    watch: {
        $route() {
            this.getTokenAsync(this.id);
        },

        /**
        * Update data after the language is changed.
        */
        '$i18n.locale': function () {
            this.getTokenAsync(this.id);
        }
    }
};
</script>