<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="home_quotes">
                    <loading-indicator v-if="!symbols" />
                    <div v-else class="right-qu-reverse">
                        <template>

                            <symbol-list ref="sym_list" :symbols="symbols" :typeFilter="3"
                                @symbol-selected="gotoTradePage" />
                        </template>

                        <div class="d-flex text-header">
                            <div>{{ $t('home.label_up_symbols') }}</div>
                            <router-link to="/quotes">
                                {{ $t('home.label_more') }}
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.home_quotes {
    background: #fff;
    color: #000;
}
hr {
    background-color: var(--divider-secondary);
    opacity: 0.1;
}

.text-header {
    justify-content: space-between;
    margin-bottom: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
    line-height: 33px;
    font-style: normal;
}

.text-header a {

    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #9F9F9F;
    line-height: 22px;
    font-style: normal;
}

.right-qu-reverse {
    display: flex;
    flex-direction: column-reverse;
    font-size: 17px;
}
@media(max-width:1024px){
    .right-qu-reverse{
        display: flex;
        flex-direction: column-reverse;
        font-size: 10px;
    }
}
</style>

<script>
import SymbolList from './SymbolListCrease.vue';

export default {
    components: { SymbolList },

    props: ['symbols'],

    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        getVisibleSids: function () {
            return this.symbols ? this.$refs.sym_list.getVisibleSids() : [];
        }
    }
};
</script>