<template>
    <section class="page settings-page">
        <div class="head-banner">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/user">{{ $t('home.footer_personal_settings') }}</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">{{ $t('referrals.title') }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h1>{{ $t('referrals.title') }}</h1>
                        <p>{{ $t('referrals.desp') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- loading -->
        <loading-indicator v-if="loading" />
        <template v-else>
            <div v-if="!referralCode" class="sign-in-notice">
                <h1>{{ $t('referrals.earn_now') }}</h1>
                <div>
                    <router-link class="btn btn-primary" :to="loginLink">{{ $t('general.login') }}</router-link>
                </div>
            </div>
            <div v-else class="container">
                <div class="row">
                    <div class="col">
                        <h1>{{ $t('referrals.promotion_code_and_link') }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="referral-part">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label class="form-label opacity-50">{{ $t('referrals.promotion_code') }}</label>
                                        <div>
                                            <span id="referral-code" class="fw-bold">{{ referralCode }}</span>
                                            <a href="javascript:;" data-toggle="copy" data-target="#referral-code" class="copy">{{ $t('general.copy') }}</a>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label opacity-50">{{ $t('referrals.promotion_link') }}</label>
                                        <div>
                                            <span id="referral-link" class="fw-bold">{{ referralLink }}</span>
                                            <a href="javascript:;" data-toggle="copy" data-target="#referral-link" class="copy">{{ $t('general.copy') }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto" v-if="qrImageUrl">
                                    <div class="form-group">
                                        <label class="form-label opacity-50">{{ $t('referrals.qr_image') }}</label>
                                        <div>
                                            <img :src="qrImageUrl" alt="QR Image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Prompted users and commission details -->
                <div class="row">
                    <div class="col">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: visibleTab === 'prompted' }" @click="visibleTab = 'prompted'" href="javascript:;">{{
                                    $t('referrals.promoted_users')
                                }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: visibleTab === 'commission' }" @click="visibleTab = 'commission'" href="javascript:;">{{
                                    $t('referrals.commission_history')
                                }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col">
                        <div class="referral-part">
                            <prompted-users v-if="visibleTab === 'prompted'" />
                            <commission-history v-if="visibleTab === 'commission'" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<style scoped>
.copy{
    color: #0362fa;
}
.settings-page {
    background: #fff;
}

.sign-in-notice {
    background: #f6f7fb;
    padding: 4rem 0;
    width: 100%;
    text-align: center;
}
.sign-in-notice h1 {
    font-size: 2.5rem;
    margin: 0 0 2rem 0;
}
.sign-in-notice .btn {
    min-width: 10rem;
}

.referral-part,
.referral-step {
    background: #fafafa;
    border-radius: 0.25rem;
    padding: 1rem;
}

.referral-step {
    position: relative;
    color: #00000015;
    font-size: 4rem;
    height: 10rem;
}
.referral-step > svg {
    position: absolute;
    width: 6rem;
    height: 6rem;
    top: 2rem;
    right: 2rem;
    opacity: 0.6;
}

.referral-part img {
    max-height: 10rem;
}

h6 {
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 1rem;
}

/**
 * ======================================================================================
 * head-banner
 */
.head-banner {
    width: 100%;
    padding: 0.5rem 0 3rem 0;
    background: rgb(15, 15, 17) url('@/assets/images/web-banner-bg.png') no-repeat scroll 50% 0px;
    color: #fff;
}
.head-banner h1 {
    font-size: 2rem;
    color: rgb(244, 205, 172);
}

.head-banner .breadcrumb-item.active {
    color: #fff9;
}
.head-banner .breadcrumb-item > a {
    color: #fff;
}

.nav-tabs {
    border: 0;
}
.nav-tabs .nav-link {
    border: 0;
    color: var(--primary-base);
    font-size: 1.25rem;
    padding: 0.25rem 1rem 0 0;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
}
.nav-tabs .nav-link.active {
    color: var(--bs-body-color);
    font-size: 1.5rem;
    padding-top: 0;
    font-weight: 600;
}
</style>
<script>
import 'axios';
import axios from 'axios';
import PromptedUsers from './Components/PromptedUsers.vue';
import CommissionHistory from './Components/CommissionHistory.vue';

export default {
    components: { PromptedUsers, CommissionHistory },
    data() {
        return {
            loading: true,

            referralLink: null,
            referralCode: null,
            qrImageUrl: null,

            // The link to the login page.
            loginLink: null,

            // Which tab to display: commision or prompted?
            visibleTab: 'prompted'
        };
    },

    created() {
        this.loginLink = '/user/login?from=' + encodeURIComponent(this.$route.path);
        this.getProfileAsync();
    },

    methods: {
        getProfileAsync: async function () {
            const self = this;

            try {
                const resp = await axios.post('/api/v1/account/profile');
                const json = resp.data;
                if (json && json.data) {
                    self.referralCode = json.data.referralCode;
                    self.referralLink = window.location.origin + json.data.referralLink;
                    self.loading = false;

                    // Begin to load qr image
                    // self.getQRImageAsync();
                    //  Generate qr code.
                    // QRCode.toDataURL(self.referralLink).then((url) => {
                    //     console.log(`${url}`);
                    //     self.qrImageUrl = url;
                    // });

                    Vue.nextTick(() => {
                        $('a[data-toggle=copy]').enable_copy();
                    });
                }
            } catch (err) {
                if (err.response) {
                    const resp = err.response;
                    if (resp.status === 401) {
                        // Not signed in.
                        self.loading = false;
                        self.referralCode = null;
                    }
                }
            }
        }
    }
};
</script>