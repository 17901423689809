<template>
    <div class="w-100 text-center prompted-symbol">
        <div class="token-icon-wrapper d-flex justify-content-center">
            <token-icon :symbol="symbol" />
        </div>
        <div class="symbol-content">
            <h2>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</h2>
            <div class="price">{{ symbol.price }}</div>
            <div :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
        </div>
    </div>
</template>

<script>
import TokenIcon from '../../Components/_TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>

<style scoped>
.prompted-symbol {
    display: flex;
    align-items: center;
}
.prompted-symbol .token-icon>* {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    background-size: cover;
    background-repeat: no-repeat;
}

.symbol-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    line-height: 2;
}
.symbol-content > h2 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin: 0;
}
.price {
    background-color: #fff;
}
.token-icon-wrapper > *{
    position: static;
    width: 100%;
    height: 100%;
}
.token-icon-wrapper {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
.col-auto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.symbol-content .up {
    color: var(--up);
    padding-left: 40px;
}

/* .symbol-content .slide-price {
    color: #acafbe;
} */
.symbol-content .up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}

.symbol-content .down {
    color: var(--down);
}

.symbol-content .down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}

.price1 {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 4px;
}

.price1.px-2 {
    color: #000;
    font-size: 14px;
    font-weight: 700;
}

.price.px-2 {
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 0;
}
@media (max-width:1024px) {
    .w-100.prompted-symbol {
        font-size: 10px;
        width: 20px;
    }

    .price1 {
        margin-bottom: 0;
        margin-left: 0;
        padding-left: 4px;
        font-size: 16px;
    }

    .price1.px-2 {
        color: #000;
        font-size: 10px;
        font-weight: normal;
    }

    .price.px-2 {
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 0;
        font-weight: normal;
        font-family: PingFangSC, PingFang SC;
    }
    .col-auto{
        padding-left: 0;
    }
}
</style>