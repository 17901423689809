<template>
    <div v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block">
            <div class="market-wrap">
                <dl class="dl-style">
                    <dd class="dd-style" v-for="(symbol, index) in filteredSymbols" :key="index"
                        @click="gotoTrade(symbol)">
                        <router-link :to="symbol.getRoutePath()" v-if="index < 4">
                            <div class="text-uppercase d-flex align-items-center">
                                <div class="before-sign">
                                </div>
                                <div>{{ symbol.metadata.name }}</div>
                            </div>
                            <div growing-ignore="true">
                                <span :class="symbol.up ? 'rate up' : 'rate down'">
                                    {{ symbol.price_change_pct }}
                                </span>
                            </div>
                        </router-link>
                    </dd>
                </dl>
            </div>
        </section>
        <section class="homepage-market-sm d-lg-none d-md-none">
            <dl class="dl-style">
                <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)"
                    v-show="index < 4">
                    <div class="dd-style">
                        <dl>
                            <dt class="text-uppercase d-flex align-items-center token-name">
                                <div class="before-sign">
                                </div>
                                <div>{{ symbol.metadata.name }}</div>
                            </dt>
                        </dl>
                        <dl :class="symbol.up ? 'bg-up-mb' : 'bg-down-mb'" growing-ignore="true">{{
                            symbol.price_change_pct }}</dl>
                    </div>
                </dd>
            </dl>
        </section>
    </div>
</template>
<style scoped>
.homepage-market .market-wrap dl dd>a>div:first-child {
    width: 2px;
    height: 2px;
    border: 1px solid #C14798;
}

.homepage-market .market-wrap dl dd a {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.dl-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 20px;
}

.dd-style {
    min-width: 38%;
    margin-right: 35px;
}

.before-sign {
    margin-right: 10px;
}

@media(max-width:1024px) {
    .page .bg-up-mb {
        color: var(--up);
    }

    .page .bg-down-mb {     
        color: var(--down);
    }

    .before-sign {
        width: 20px;
        height: 2px;
        border: 1px solid #C14798;
    }

    .dd-style {
        display: flex;
        min-width: none;
        width: 100%;
        margin-right: 0;
        align-items: center;
        justify-content: space-between;
    }
}

@media(max-width:400px) {
    .homepage-market-sm > dl > dd {
        width: 100%;
    }
    .dd-style {
        display: flex;
        min-width: none;
        width: 100%;
        margin-right: 0;
        justify-content: space-between;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .homepage-market-sm {
        display: block !important;
    }

    .homepage-market {
        display: none !important;
    }
}
</style>
<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm'],

    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }

            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                const arr = this.symbols.filter((s) => {
                    return s.up && s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
                return arr.sort((a, b) => {
                    return parseFloat(b.price_change_pct.replace('%', '')) - parseFloat(a.price_change_pct.replace('%', ''));
                })
            } else {
                // Return symbols for the specified type.

                const arr = this.symbols.filter((s) => {
                    return s.up && s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                });

                return arr.sort((a, b) => {
                    return parseFloat(b.price_change_pct.replace('%', '')) - parseFloat(a.price_change_pct.replace('%', ''));
                })
            }
        }
    },

    methods: {
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },

        getVisibleSids: function () {
            const output = [];
            $(this.filteredSymbols).each((index, sym) => {
                output.push(sym.metadata.id);
            });

            return output;
        }
    }
};
</script>