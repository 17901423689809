<template>
    <div>
        <div class="settings-card container d-md-block d-none">
            <div class="assets-title-box">
                <h2 class="assets-title">{{ $t('assets.title') }}</h2>
                <router-link to="/user/balance" class="to-assets">{{ $t('coin_treasure.holding_assets')
                    }}</router-link>
            </div>
            <div class="assets-content">
                <div class="assets-count">{{ assetsCount }}</div>
                <router-link to="/user/balance" class="to-assets-btn">{{ $t('home.guide_deposit') }}</router-link>
            </div>
        </div>
        <div class="settings-card container">
            <div class="card-title-box">
                <h2 class="card-title">{{ $t('vcode.label_account_verfication') }}</h2>
                <div class="card-title-des">{{ $t('idverification.upload_desp') }}</div>
            </div>
            <div class="card-list">
                <div class="card-item">
                    <div class="card-left">
                        <img src="@/assets/images/v4.1/verification.png" alt="" class="card-item-icon">
                        <div class="card-item-box">
                            <div class="card-item-title">{{ $t('user.label_id_verification') }}</div>
                            <div class="card-item-des">{{ $t('user.label_id_verification_desp') }}</div>
                        </div>
                    </div>
                    <router-link to="/user/verifyid" class="card-item-button">{{ $t(profile.verifiedLevel >= profile.requiredIDLevel?'home.guide_Authentication':'reset_password.label_verification') }}</router-link>
                </div>
                <div class="card-item">
                    <div class="card-left">
                        <img src="@/assets/images/v4.1/phone.png" alt="" class="card-item-icon">
                        <div class="card-item-box">
                            <div class="card-item-title">{{ $t('user.label_account') }}</div>
                            <div class="card-item-des">{{ $t('user.label_account') }}</div>
                        </div>
                    </div>
                    <router-link to="/user/phone" class="card-item-button">{{ $t(profile.phone?'home.guide_Authentication':'reset_password.label_verification') }}</router-link>
                </div>
                <div class="card-item" v-if="sysconfig.requiresWithdrawPassword === true">
                    <div class="card-left">
                        <img src="@/assets/images/v4.1/payPwd.png" alt="" class="card-item-icon">
                        <div class="card-item-box">
                            <div class="card-item-title">{{ $t('user.label_financial_password') }}</div>
                            <div class="card-item-des">{{ $t('withdraw_password.title') }}</div>
                        </div>
                    </div>
                    <router-link to="/user/changewithdrawpwd" class="card-item-button">{{ $t('reset_password.label_verification') }}</router-link>
                </div>
                <div class="card-item">
                    <div class="card-left">
                        <img src="@/assets/images/v4.1/bankaccount.png" alt="" class="card-item-icon">
                        <div class="card-item-box">
                            <div class="card-item-title">{{$t('user.label_bankaccount')}}</div>
                            <div class="card-item-des">{{ $t('bank_account.label_confirm') }}</div>
                        </div>
                    </div>
                    <router-link to="/user/bankaccounts" class="card-item-button">{{ $t(this.bankaccount.length>0?'home.guide_Authentication':'reset_password.label_verification') }}</router-link>
                </div>
                <router-link to="/user/reset" class="card-item">
                    <div class="card-item-title">{{ $t('user.label_password')}}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
                <router-link v-if="sysconfig.numReferralLevels !== 0" to="/referrals" class="card-item">
                    <div class="card-left">{{ $t('referrals.title') }}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
                <router-link to="/user/balance" class="card-item">
                    <div class="card-left">{{ $t('home.footer_assets') }}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
                <router-link to="/notices" class="card-item">
                    <div class="card-left">{{ $t('notices.notice_title') }}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
                <router-link to="/exchange/orders" class="card-item">
                    <div class="card-left">{{ $t('home.header_exchange_orders') }}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
                <router-link to="/futures/openorders" class="card-item">
                    <div class="card-left">{{ $t('home.header_futures_orders') }}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
                <router-link to="/boption/orders" class="card-item" v-if="sysconfig.bOptionEnabled">
                    <div class="card-left">{{ $t('home.header_boption_orders') }}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
                <router-link to="/user/signout" class="card-item">
                    <div class="card-left">{{ $t('user.label_signout') }}</div>
                    <svg viewBox="0 0 256 256">
                        <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                    </svg>
                </router-link>
            </div>
        </div>
        <div class="container d-md-block d-none">
            <div class="settings-card-banner">
                <div class="card-left-content">
                    <div class="card-left-title">{{ $t('intro.why_us_title') }}</div>
                    <div class="card-left-title">{{ $t('intro.why_us_secure_title') }}</div>
                    <div class="card-left-gradient">{{ $t('intro.why_us_fast_title') }}</div>
                    <div class="card-left-gradient">{{ $t('intro.why_us_stable_title') }}</div>
                    <div class="card-left-des">{{ $t('intro.md_desp') }}</div>
                </div>
                <img class="banner-right-img" src="../../../assets/images/v4.1/userBanner.png" alt="">
            </div>
        </div>
    </div>
</template>
<style scoped>
.settings-card {
    padding: 20px 20px;
}
.card-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 21px;
    color: #111111;
    line-height: 40px;
    margin-bottom: 4px;
}
.card-title-box {
    margin-bottom: 12px;
    word-break: break-all;
}
.card-title-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #7F8490;
    line-height: 17px;
}
.card-left {
    display: flex;
    align-items: center;
    width: 50%;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000922;
    line-height: 22px;
    flex: 1;
}
.card-item-icon {
    margin-right: 20px;
    width: 24px;
}
.card-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-top: 1px solid #EDEEEF;
    align-items: center;
    color: #000;
    margin: 0;
    word-break: keep-all;
}
.card-item:hover {
    background: none;
    color: #000;
}
.card-item svg {
    fill: #000;
    stroke: #000;
    margin: 0;
}
.card-item:first-child {
    border: none;
}
.card-item-box {
    word-break: break-all;
}
.card-item-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000922;
    line-height: 22px;
    margin-bottom: 5px;
}
.card-item-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #7F8490;
    line-height: 17px;
}
.card-item-button {
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    padding: 4px 13px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #000922;
    line-height: 17px;
    margin: 0;
}
.assets-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.assets-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #111111;
    line-height: 40px;
}
.to-assets {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #437BED;
    line-height: 20px;
}
.to-assets:hover {
    background: none;
    color: #437BED;
}
.to-assets-btn {
    width: 150px;
    height: 32px;
    background: #437BED;
    border-radius: 4px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    padding: 0;
    margin: 0;
}
.assets-count {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #111111;
    line-height: 28px;
    margin: 23px 0;
}
.settings-card-banner {
    display: flex;
    justify-content: space-between;
    background: #E6EFFF;
    padding: 0;
    margin-bottom: 60px;
}
.banner-right-img {
    height: 300px;
    width: auto;
}
.card-left-content {
    padding: 40px;
}
.card-left-title {
    font-family: AppleSDGothicNeo, AppleSDGothicNeo;
    font-weight: 600;
    font-size: 36px;
    color: #437BED;
    line-height: 43px;
    margin-bottom: 12px;
}
.card-left-gradient {
    height: 36px;
    text-indent: 12px;
    border-radius: 18px;
    background: linear-gradient( 90deg, #9DBEF9 0%, rgba(159,191,249,0.33) 51%, rgba(159,192,249,0.06) 77%, rgba(157,190,249,0.02) 100%);
    font-family: AppleSDGothicNeo, AppleSDGothicNeo;
    font-weight: 600;
    font-size: 18px;
    color: #1C53C4;
    line-height: 36px;
    margin-bottom: 12px;
}
.card-left-des {
    font-family: AppleSDGothicNeo, AppleSDGothicNeo;
    font-weight: 600;
    font-size: 18px;
    color: #437BED;
    line-height: 21px;
}
@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }
}
</style>
<script>
export default { 
    props: ['profile'],
    data(){
        return {
            assetsCount:'--',
            bankaccount:[]
        }
    },
    created(){
        this.getAssetsCount()
        this.initAsync();
    },
    methods:{
        initAsync: async function () {
            // List all accounts with payment source as bank.
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0) {
                this.bankaccount = json.data;
            }
        },
        getAssetsCount:async function(){
            const balances = await $.callPostApi(this, '/api/v1/balance/list');
            this.assetsCount = balances[0].balance + ' ' + balances[0].currency
        }
    }
};
</script>