<template>
    <form class="login-form" method="post" action="javascript:void(0)" v-on:submit="do_login">
        <input type="hidden" name="__RequestVerificationToken" v-bind:value="csrfToken" />
        <div class="form-group">
            <label>{{ $t('login.account') }}</label>

            <div class="d-flex">
                <region-selector v-bind:regions="regions" />
                <div class="flex-fill">
                    <input name="UserName" data-val="true" data-val-required="*" pattern="\d*" type="text"
                        class="form-control round-right phone" v-model.trim="UserName"
                        :placeholder="$t('login.account')" />
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="Password">{{ $t('login.password') }}</label>
            <div class="pwd-box">
                <input name="Password" type="password" autocomplete="off" data-val="true" data-val-required="*"
                    class="form-control" :placeholder="$t('login.password')" :type="showPassword ? 'text' : 'password'"
                    v-model="pwd" />
                <svg viewBox="0 0 256 256" class="svg-icon showPwd-icon" @click="showPassword = !showPassword"
                    :class="showPassword ? 'active' : ''">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-displayPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group">
            <submit-button ref="submitButton" :text="$t('login.login_button')" />
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <router-link to="/user/reset">{{ $t('login.forgot_password') }}</router-link>
                </div>
                <!-- Do we need to display the registration link? If could be configured on the server. -->
                <div class="col text-right">
                    <router-link to="/user/create">{{ $t('login.free_register') }}</router-link>
                </div>
            </div>
        </div>
    </form>
</template>
<style scoped>
.pwd-box {
    position: relative;
}

.pwd-box .showPwd-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
}

.showPwd-icon.active {
    fill: var(--primary-base);
    stroke: var(--primary-base);
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import "@/assets/svg/v4.2/v4.2-displayPassword.svg";

export default {
    components: { RegionSelector },

    props: ['regions', 'csrfToken'],

    created() {
        const regions = this.regions;
        if (!regions || !Array.isArray(regions)) throw new Error('An array of supported regions is required.');

        const token = this.csrfToken;
        if (!token || typeof token !== 'string') throw new Error('A valid CSRF token is required.');

        if (this.supportMultipleRegions) {
            this.regionCode = regions[0].areaCode;
        }
    },

    data() {
        return {
            regionCode: null,
            showPassword: false,
            pwd: ""
        };
    },

    computed: {
        supportMultipleRegions: function () {
            const regions = this.regions;
            return regions != null && regions.length > 0;
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        do_login: function (e) {
            const self = this;
            const frm = $(e.target);
            if (frm.valid()) {
                self.$refs.submitButton.submit();

                const _on_submit_error = function (err) {
                    const _callback = function () {
                        $.top_error(err || self.$t('login.login_failed'));
                        self.$refs.submitButton.reset();
                    };
                    setTimeout(_callback, 5000);
                };

                self.$http
                    .post('/api/v1/account/signin', frm.serialize(), { headers: { 'X-XSRF-TOKEN': self.csrfToken } })
                    .then((resp) => {
                        const json = resp.data;

                        if (json && json.errcode == 0) {
                            // Save the user profile.
                            setProfile(json.data);

                            self.$refs.submitButton.complete();
                            setTimeout(function () {
                                // Redirect to user home if not specified.
                                const from = $.trim(self.$route.query.from);
                                if (from.length && from.startsWith('/')) {
                                    self.$router.push(from);
                                } else {
                                    self.$router.push('/user');
                                }
                            }, 200);
                        } else {
                            _on_submit_error(json.errmsg);
                        }
                    })
                    .catch((err) => {
                        console.log('Login failed' + JSON.stringify(err));
                        _on_submit_error();
                    });
            }
        }
    }
};
</script>